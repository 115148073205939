import { DELETE, GET, PATCH, POST } from 'lib/configuration';
import {
	CenterDetailType,
	CenterMembershipType,
	GymDetailType,
	LockerGroupType,
	LockerType,
	ItemDetailType,
	ItemType,
	MemoType,
	PaginatedCenterMemberships,
	PaginatedCenters,
	PaginatedGyms,
	PaginatedLockerGroups,
	PaginatedLockers,
	PaginatedItems,
	PaginatedUsers,
	UserType
} from 'lib/types';

export const UserApi = {
	list: GET<PaginatedUsers>('/users'),
	me: GET<{ user: UserType }>('/users/me'),
	detail: GET<{ user: UserType }>('/users/:id'),
	create: POST<{ user: UserType }>('/users'),
	update: PATCH<{ user: UserType }>('/users/:id'),
	delete: DELETE<{ user: UserType }>('/users/:id')
};

export const AuthApi = {
	signIn: POST<{ message: string }>('/auth/signIn'),
	signOut: DELETE<{ message: string }>('/auth/signOut')
};

export const GymApi = {
	list: GET<PaginatedGyms>('/gyms'),
	me: GET<{ gyms: GymDetailType[] }>('/gyms/me'),
	detail: GET<{ gym: GymDetailType }>('/gyms/:id'),
	create: POST<{ gym: GymDetailType }>('/gyms'),
	update: PATCH<{ gym: GymDetailType }>('/gyms/:id'),
	delete: DELETE<{ gym: GymDetailType }>('/gyms/:id')
};

export const CenterApi = {
	list: GET<PaginatedCenters>('/centers'),
	me: GET<{ centers: CenterDetailType[] }>('/centers/me'),
	detail: GET<{ center: CenterDetailType }>('/centers/:id'),
	create: POST<{ center: CenterDetailType }>('/centers'),
	update: PATCH<{ center: CenterDetailType }>('/centers/:id'),
	delete: DELETE<{ center: CenterDetailType }>('/centers/:id')
};

export const CenterMembershipApi = {
	list: GET<PaginatedCenterMemberships>('/centerMemberships'),
	detail: GET<{ centerMembership: CenterMembershipType }>('/centerMemberships/:id'),
	delete: DELETE<{ centerMembership: CenterMembershipType }>('/centerMemberships/:id')
};

export const ItemApi = {
	list: GET<PaginatedItems>('/items'),
	detail: GET<{ item: ItemDetailType }>('/items/:id'),
	create: POST<{ item: ItemType }>('/items'),
	update: PATCH<{ item: ItemDetailType }>('/items/:id'),
	delete: DELETE<{ item: ItemDetailType }>('/items/:id')
};

export const MemoApi = {
	list: GET<{ memos: MemoType[] }>('/memos'),
	detail: GET<{ memo: MemoType }>('/memos/:id'),
	create: POST<{ memo: MemoType }>('/memos'),
	update: PATCH<{ memo: MemoType }>('/memos/:id'),
	delete: DELETE<{ memo: MemoType }>('/memos/:id')
};

export const LockerApi = {
	list: GET<PaginatedLockers>('/lockers'),
	detail: GET<{ locker: LockerType }>('/lockers/:id'),
	update: PATCH<{ locker: LockerType }>('/lockers/:id'),
	delete: DELETE<{ locker: LockerType }>('/lockers/:id')
};

export const LockerGroupApi = {
	list: GET<PaginatedLockerGroups>('/lockerGroups'),
	detail: GET<{ lockerGroup: LockerGroupType }>('/lockerGroups/:id'),
	create: POST<{ lockerGroup: LockerGroupType }>('/lockerGroups'),
	update: PATCH<{ lockerGroup: LockerGroupType }>('/lockerGroups/:id'),
	delete: DELETE<{ lockerGroup: LockerGroupType }>('/lockerGroups/:id')
};
