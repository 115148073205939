import { ItemPaymentTypes } from 'lib/types';
import React from 'react';
import styled from 'styled-components';

interface PaymentSelectProps {
	value: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Select = styled.select`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Option = styled.option``;

const PaymentSelect: React.FC<PaymentSelectProps> = ({ value, onChange }) => {
	return (
		<>
			<Label htmlFor="paymentType">Payment Type</Label>
			<Select name="paymentType" value={value} onChange={onChange}>
				<Option value="">Not Selected</Option>
				<Option value={ItemPaymentTypes.PERIODIC}>Periodic</Option>
				<Option value={ItemPaymentTypes.COUNT_BASED}>Count Based</Option>
			</Select>
		</>
	);
};

export default PaymentSelect;
