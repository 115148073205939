import React from 'react';
import { CenterType } from 'lib/types';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

interface KakaoMapProps {
	location: CenterType['location'];
}

const KakaoMap: React.FC<KakaoMapProps> = ({ location }) => {
	const defaultCenter = {
		lat: 37.5642135,
		lng: 127.0016985
	};

	const center =
		location?.latitude && location.longitude ? { lat: location?.latitude, lng: location?.longitude } : defaultCenter;

	return (
		<Map
			center={center}
			style={{ width: '100%', height: '350px', zIndex: 0 }}
			level={3} // 지도의 확대 레벨
		>
			{location && <MapMarker position={center} />}
		</Map>
	);
};

export default KakaoMap;
