import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserDetail from 'page/user/userDetail/UserDetail';
import UserList from 'page/user/userList/UserList';
import { ROUTE_PATHS } from 'utils/constants/route.constants';
import { Main } from 'components/common';

const User: React.FC = () => {
	return (
		<Main>
			<Routes>
				<Route path={ROUTE_PATHS.LIST} element={<UserList />} />
				<Route path={ROUTE_PATHS.DETAIL} element={<UserDetail />} />
			</Routes>
		</Main>
	);
};

export default User;
