import React from 'react';
import styled from 'styled-components';
import { CenterType } from 'lib/types';

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
`;

const Tr = styled.tr`
	&:nth-child(odd) {
		background-color: #fff;
	}
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
`;

const Td = styled.td`
	border: 1px solid #ddd;
	padding: 8px;
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: red;
`;

const CentersTableWithAdd: React.FC<{ centers: CenterType[]; onAddCenter: (center: CenterType) => void }> = ({
	centers,
	onAddCenter
}) => {
	const handleAddCenter = (center: CenterType) => {
		onAddCenter(center);
	};

	return (
		<Table>
			<thead>
				<tr>
					<Th>Name</Th>
					<Th>Address</Th>
					<Th>Phone Number</Th>
					<Th>Digit Code</Th>
					<Th>Add</Th>
				</tr>
			</thead>
			<tbody>
				{centers.map((center) => (
					<Tr key={center._id}>
						<Td>{center.name}</Td>
						<Td>{center.address}</Td>
						<Td>{center.phoneNumber}</Td>
						<Td>{center.digitCode}</Td>
						<Td>
							<Button onClick={() => handleAddCenter(center)}>+</Button>
						</Td>
					</Tr>
				))}
			</tbody>
		</Table>
	);
};

export default CentersTableWithAdd;
