import React from 'react';
import styled from 'styled-components';
import { UserType } from 'lib/types';

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
`;

const Tr = styled.tr`
	&:nth-child(odd) {
		background-color: #fff;
	}
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
`;

const Td = styled.td`
	border: 1px solid #ddd;
	padding: 8px;
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: red;
`;

const UsersTableWithAdd: React.FC<{ users: UserType[]; onAddUser: (user: UserType) => void }> = ({
	users,
	onAddUser
}) => {
	const handleAddUser = (user: UserType) => {
		onAddUser(user);
	};

	return (
		<Table>
			<thead>
				<tr>
					<Th>Name</Th>
					<Th>Email</Th>
					<Th>Phone Number</Th>
					<Th>Add</Th>
				</tr>
			</thead>
			<tbody>
				{users.map((user) => (
					<Tr key={user._id}>
						<Td>{user.name}</Td>
						<Td>{user.email}</Td>
						<Td>{user.phoneNumber}</Td>
						<Td>
							<Button onClick={() => handleAddUser(user)}>+</Button>
						</Td>
					</Tr>
				))}
			</tbody>
		</Table>
	);
};

export default UsersTableWithAdd;
