import { MemoType, UserType } from 'lib/types';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { MemoApi } from 'lib'; // MemoApi 임포트
import Toast, { ToastType } from 'utils/toast'; // Toast 임포트
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/reducers/user';

interface UserUpdateMemoModalProps {
	isOpen: boolean;
	memo: MemoType;
	onRequestClose: () => void;
	onMemoUpdated: (updatedMemo: MemoType) => void; // 메모 업데이트 후 콜백
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Textarea = styled.textarea`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	resize: none;
`;

const Button = styled.button`
	padding: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: #007bff;
	color: white;
`;

export const UserUpdateMemoModal: React.FC<UserUpdateMemoModalProps> = ({
	isOpen,
	memo,
	onRequestClose,
	onMemoUpdated
}) => {
	const [content, setContent] = useState(memo.content);
	const { user: author } = useSelector(userSelector);

	useEffect(() => {
		if (isOpen) {
			setContent(memo.content);
		}
	}, [isOpen, memo.content]);

	const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setContent(e.target.value);
	};

	const handleUpdateMemo = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const updatedMemo: MemoType = { ...memo, content };
			updatedMemo.author = author as UserType;
			delete updatedMemo.createdAt;
			delete updatedMemo.updatedAt;
			delete updatedMemo._id;
			const response = await MemoApi.update(memo._id, updatedMemo);
			Toast({ message: 'Memo updated successfully', type: ToastType.SUCCESS });
			onMemoUpdated(response.memo);
			onRequestClose();
		} catch (error) {
			Toast({ message: 'Error updating memo', type: ToastType.ERROR });
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="Update Memo Modal"
			ariaHideApp={false}
			shouldCloseOnEsc
		>
			<h2>Update Memo</h2>
			<Form onSubmit={handleUpdateMemo}>
				<Label>Content</Label>
				<Textarea value={content} onChange={handleContentChange} rows={4} />
				<Button type="submit">Update Memo</Button>
			</Form>
		</Modal>
	);
};
