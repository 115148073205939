export enum ROUTE_NAMES {
	HOME = '/',
	SIGN_IN = '/signIn',
	SIGN_UP = '/signUp',
	POLICY = '/policy',
	// ADMIN
	USER = '/users',
	USER_MY_PAGE = '/users/myPage',
	GYM = '/gyms',
	CENTER = '/center',
	ITEM = '/item',
	CENTER_MEMBERSHIP = '/centerMembership',
	LOCKER = '/locker',
	LESSON = '/lesson'
}

export enum ROUTE_PATHS {
	ALL = '/*',
	LIST = '/',
	WRITE = '/write',
	DETAIL = '/:id'
}
