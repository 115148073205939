import { CenterMembershipType, MemoType, UserType } from 'lib/types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { MemoApi } from 'lib'; // MemoApi 임포트
import Toast, { ToastType } from 'utils/toast'; // Toast 임포트
import styled from 'styled-components';

interface UserAddMemoModalProps {
	isOpen: boolean;
	user: UserType;
	author: UserType;
	centerMembership?: CenterMembershipType;
	onRequestClose: () => void;
	onMemoAdded: (newMemo: MemoType) => void; // 메모 추가 후 콜백
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Textarea = styled.textarea`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	resize: none;
`;

const Button = styled.button`
	padding: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: #007bff;
	color: white;
`;

export const UserAddMemoModal: React.FC<UserAddMemoModalProps> = ({
	isOpen,
	user,
	author,
	centerMembership,
	onRequestClose,
	onMemoAdded
}) => {
	const [content, setContent] = useState('');

	const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setContent(e.target.value);
	};

	const handleAddMemo = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const newMemo: MemoType = {
				content,
				user,
				author
			};
			if (centerMembership) newMemo.centerMembership = centerMembership;
			const response = await MemoApi.create(newMemo);
			Toast({ message: 'Memo added successfully', type: ToastType.SUCCESS });
			onMemoAdded(response.memo); // 메모 추가 후 콜백 호출
			setContent(''); // 입력 필드 초기화
			onRequestClose(); // 모달 닫기
		} catch (error) {
			Toast({ message: 'Error adding memo', type: ToastType.ERROR });
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="Add Memo Modal"
			ariaHideApp={false}
			shouldCloseOnEsc
		>
			<h2>Add Memo</h2>
			<Form onSubmit={handleAddMemo}>
				<Label>Content</Label>
				<Textarea value={content} onChange={handleContentChange} rows={4} />
				<Button type="submit">Add Memo</Button>
			</Form>
		</Modal>
	);
};
