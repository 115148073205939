import appleIcon from 'assets/images/apple-logo.svg';
import bodyIcon from 'assets/images/body.svg';
import bodyFatIcon from 'assets/images/bodyFat.svg';
import googleIcon from 'assets/images/google-logo.svg';
import gymWearIcon from 'assets/images/gymWear.svg';
import kakaoIcon from 'assets/images/kakaotalk-logo.svg';
import lockerIcon from 'assets/images/locker.svg';
import parkingIcon from 'assets/images/parking.svg';
import showerIcon from 'assets/images/shower.svg';
import towelIcon from 'assets/images/towel.svg';
import wifiIcon from 'assets/images/wifi.svg';
import { CenterFacility, SocialLoginTypes } from 'lib/types';

export { appleIcon };
export { bodyIcon };
export { bodyFatIcon };
export { googleIcon };
export { gymWearIcon };
export { kakaoIcon };
export { lockerIcon };
export { parkingIcon };
export { showerIcon };
export { towelIcon };
export { wifiIcon };

export const getFacilityIcon = (facility: CenterFacility): string => {
	switch (facility) {
		case CenterFacility.BODY_COMPOSITION_ANALYSIS:
			return bodyIcon;
		case CenterFacility.BODY_FAT_TEST:
			return bodyFatIcon;
		case CenterFacility.LOCKER:
			return lockerIcon;
		case CenterFacility.PARKING:
			return parkingIcon;
		case CenterFacility.SHOWER:
			return showerIcon;
		case CenterFacility.TOWEL:
			return towelIcon;
		case CenterFacility.WIFI:
			return wifiIcon;
		case CenterFacility.GYM_CLOTHING:
			return gymWearIcon;
		default:
			return '';
	}
};

export const getSocialLoginIcon = (socialLoginType: SocialLoginTypes): string => {
	switch (socialLoginType) {
		case SocialLoginTypes.GOOGLE:
			return googleIcon;
		case SocialLoginTypes.KAKAO:
			return kakaoIcon;
		case SocialLoginTypes.APPLE:
			return appleIcon;
		default:
			return '';
	}
};
