import React from 'react';
import styled from 'styled-components';
import { CenterType } from 'lib/types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
`;

const Tr = styled.tr`
	&:nth-child(odd) {
		background-color: #fff;
	}
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	&:hover {
		background-color: #e9ecef; /* Light gray background on hover */
	}
`;

const Td = styled.td`
	border: 1px solid #ddd;
	padding: 8px;
	cursor: pointer;
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: red;
`;

const CentersTableWithDelete: React.FC<{ centers: CenterType[]; onRemoveCenter: (center: CenterType) => void }> = ({
	centers,
	onRemoveCenter
}) => {
	const navigate = useNavigate();

	const handleRemoveCenter = (center: CenterType) => {
		onRemoveCenter(center);
	};

	const onClickCenter = (center: CenterType) => navigate(`${ROUTE_NAMES.CENTER}/${center._id}`);

	return (
		<Table>
			<thead>
				<tr>
					<Th>Name</Th>
					<Th>Address</Th>
					<Th>Phone Number</Th>
					<Th>Digit Code</Th>
					<Th>Delete</Th>
				</tr>
			</thead>
			<tbody>
				{centers.map((center) => (
					<Tr key={center._id}>
						<Td onClick={() => onClickCenter(center)}>{center.name}</Td>
						<Td onClick={() => onClickCenter(center)}>{center.address}</Td>
						<Td onClick={() => onClickCenter(center)}>{center.phoneNumber}</Td>
						<Td onClick={() => onClickCenter(center)}>{center.digitCode}</Td>
						<Td>
							<Button onClick={() => handleRemoveCenter(center)}>x</Button>
						</Td>
					</Tr>
				))}
			</tbody>
		</Table>
	);
};

export default CentersTableWithDelete;
