import React from 'react';
import { addDays, format } from 'date-fns';
import styled from 'styled-components';

interface DateSelectorProps {
	selectedDate: Date;
	onDateChange: (date: Date) => void;
}

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
`;

const Button = styled.button`
	background-color: #4caf50;
	color: white;
	border: none;
	padding: 10px;
	margin: 0 10px;
	cursor: pointer;

	&:hover {
		background-color: #45a049;
	}
`;

const DateDisplay = styled.span`
	font-size: 1.2em;
	margin: 0 10px;
`;

const DateSelector: React.FC<DateSelectorProps> = ({ selectedDate, onDateChange }) => {
	const handlePreviousDay = () => {
		onDateChange(addDays(selectedDate, -1));
	};

	const handleNextDay = () => {
		onDateChange(addDays(selectedDate, 1));
	};

	return (
		<Container>
			<Button onClick={handlePreviousDay}>Previous Day</Button>
			<DateDisplay>{format(selectedDate, 'yyyy-MM-dd')}</DateDisplay>
			<Button onClick={handleNextDay}>Next Day</Button>
		</Container>
	);
};

export default DateSelector;
