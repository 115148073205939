import React from 'react';
import { Main } from 'components/common';
import Toast, { ToastType } from 'utils/toast';

const notify = () => Toast({ message: 'Notify!', type: ToastType.INFO });

const HOME: React.FC = () => {
	return (
		<Main>
			<h2>Main Content</h2>
			<p>This is the main content area.</p>
			<button type="button" onClick={notify}>
				Notify!
			</button>
		</Main>
	);
};

export default HOME;
