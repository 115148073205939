import { createSlice } from '@reduxjs/toolkit';
import { CenterType } from 'lib/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/';

interface centerState {
	center: CenterType | null;
}

const initialState: centerState = {
	center: null
};

export const slice = createSlice({
	name: 'center',
	initialState,
	reducers: {
		setCenter: (state, { payload }: PayloadAction<CenterType>) => {
			state.center = payload;
		},
		clearCenter: (state) => {
			state.center = null;
		}
	}
});

export const centerName = slice.name;
export const centerReducer = slice.reducer;
export const centerActions = slice.actions;

export const centerSelector = (state: RootState) => state.center;

export default slice.reducer;
