import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { REACT_APP_BACKEND_BASE_URL } from 'utils/environment';

const instance = axios.create({
	baseURL: REACT_APP_BACKEND_BASE_URL,
	timeout: 1000,
	// cookie 전달을 위한 설정
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json'
	}
});

const { interceptors } = instance;

/** Request interceptor */
interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		// Config setting
		if (config.headers) {
			if (!config.headers['Content-Type']) {
				config.headers['Content-Type'] = 'application/json; charset=utf-8';
			}
		}
		config.headers['Access-Control-Allow-Origin'] = REACT_APP_BACKEND_BASE_URL;
		return config;
	},
	(error) => {
		return Promise.reject(error.request);
	}
);

/** Response interceptor */
interceptors.response.use(
	(response: AxiosResponse) => response.data,
	(error) => {
		if (error.response?.status === 422) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error.response);
	}
);

type Url = string;
type Headers = object;
type Args<T> = any[] | T[];
type MappedURL = string;
type Process<T> = (mappedURL: MappedURL, args: Args<T>) => Promise<any>;

const request = <T>(url: Url, process: Process<T>): ((...args: Args<T>) => Promise<T>) => {
	const tokens = url.split('/');
	return (...args: Args<T>): Promise<T> =>
		new Promise((resolve) => {
			const mappedURL = tokens.map((token) => (token.startsWith(':') ? args.shift() : token)).join('/');
			return resolve(process(mappedURL, args));
		});
};

export const GET = <T>(URL: Url, headers: Headers = {}): ((...arg: Args<T>) => Promise<T>) =>
	request<T>(URL, (mappedURL: MappedURL, args: Args<T>) => {
		const [params] = args;
		return instance.get(mappedURL, { params, headers });
	});
export const DELETE = <T>(URL: Url, headers: Headers = {}): ((...arg: Args<T>) => Promise<T>) =>
	request<T>(URL, (mappedURL: MappedURL, args: Args<T>) => {
		const [params] = args;
		return instance.delete(mappedURL, { params, headers });
	});
export const POST = <T>(URL: Url, headers: Headers = {}): ((...arg: Args<T>) => Promise<T>) =>
	request<T>(URL, (mappedURL: MappedURL, args: Args<T>) => {
		const [body, params] = args;
		return instance.post(mappedURL, body, { params, headers });
	});
export const PATCH = <T>(URL: Url, headers: Headers = {}): ((...arg: Args<T>) => Promise<T>) =>
	request<T>(URL, (mappedURL: MappedURL, args: Args<T>) => {
		const [body, params] = args;
		return instance.patch(mappedURL, body, { params, headers });
	});
export const PUT = <T>(URL: Url, headers: Headers = {}): ((...arg: Args<T>) => Promise<T>) =>
	request<T>(URL, (mappedURL: MappedURL, args: Args<T>) => {
		const [body, params] = args;
		return instance.patch(mappedURL, body, { params, headers });
	});

export default instance;
