import React from 'react';
import styled from 'styled-components';
import { LockerEnumStatus, LockerGroupType, LockerType } from 'lib/types';

interface LockerGridProps {
	lockerGroup: LockerGroupType;
	onLockerClick: (locker: LockerType) => void;
}

interface LockerStatusProps {
	status?: LockerEnumStatus;
}

const GridContainer = styled.div<{ rows: number; columns: number }>`
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	grid-template-rows: repeat(${(props) => props.rows}, auto);
	gap: 10px;
`;

const LockerCell = styled.div`
	width: 100px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #f8f9fa;
	&:hover {
		cursor: pointer;
		background-color: #e9ecef;
	}
`;

const LockerNumber = styled.div`
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 5px;
`;

const getTextColor = (status?: LockerEnumStatus): string => {
	switch (status) {
		case LockerEnumStatus.InUse:
			return 'red';
		case LockerEnumStatus.Available:
			return 'green';
		case LockerEnumStatus.Unavailable:
			return 'darkred';
		case LockerEnumStatus.ExpiringSoon:
			return 'orange';
		case LockerEnumStatus.Expired:
			return 'gray';
		default:
			return 'black';
	}
};

const LockerStatus = styled.div<LockerStatusProps>`
	color: ${(props) => getTextColor(props.status)};
`;

const LockerGrid: React.FC<LockerGridProps> = ({ lockerGroup, onLockerClick }) => {
	const { row, quantity, lockers } = lockerGroup;
	const columns = Math.ceil(quantity / row); // 행의 개수
	const rows = row; // 필요한 열의 개수

	const handleClick = (locker: LockerType) => onLockerClick(locker);

	return lockers ? (
		<GridContainer rows={rows} columns={columns}>
			{Array.from({ length: quantity }, (_, index) => (
				<LockerCell key={index} onClick={() => handleClick(lockers[index])}>
					{lockers[index] && (
						<>
							<LockerNumber>{lockers[index].number}</LockerNumber>
							<LockerStatus status={lockers[index].status}>{lockers[index].status}</LockerStatus>
						</>
					)}
				</LockerCell>
			))}
		</GridContainer>
	) : null;
};

export default LockerGrid;
