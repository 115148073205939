import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { UserGender, UserRole, UserType } from 'lib/types';
import { UserApi } from 'lib';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';
import Toast, { ToastType } from 'utils/toast';
import { calculateAge, useDebounce } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
`;

const Input = styled.input`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Select = styled.select`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const TableHead = styled.thead`
	background-color: #f8f9fa;
`;

const TableBody = styled.tbody`
	background-color: #fff;
`;

const TableRow = styled.tr`
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	cursor: pointer;
`;

const TableHeader = styled.th`
	padding: 10px;
	text-align: left;
	border-bottom: 1px solid #dee2e6;
`;

const TableCell = styled.td`
	padding: 10px;
	border-bottom: 1px solid #dee2e6;
`;

// UserRow 컴포넌트
const UserRow: React.FC<{ user: UserType; index: number; onClick: (userId: string | undefined) => void }> = ({
	user,
	index,
	onClick
}) => {
	return (
		<TableRow onClick={() => onClick(user._id)}>
			<TableCell>{index + 1}</TableCell>
			<TableCell>{user.name}</TableCell>
			<TableCell>{user.birthDate && calculateAge(user.birthDate)}</TableCell>
			<TableCell>{user.gender}</TableCell>
			<TableCell>{user.phoneNumber}</TableCell>
			<TableCell>{user.email}</TableCell>
			<TableCell>{user.role}</TableCell>
		</TableRow>
	);
};

// UserList 컴포넌트
const UserList: React.FC = () => {
	const [users, setUsers] = useState<UserType[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [filterKey, setFilterKey] = useState('name');
	const [hasMore, setHasMore] = useState(true);
	const [filters, setFilters] = useState({
		name: '',
		email: '',
		phoneNumber: '',
		gender: '',
		role: ''
	});
	const navigate = useNavigate();

	const handleRowClick = (userId: string | undefined) => {
		navigate(`${ROUTE_NAMES.USER}/${userId}`); // 디테일 페이지로 이동
	};

	const debouncedFilters = useDebounce(filters, 500);

	const fetchUsers = useCallback(async () => {
		setLoading(true);
		try {
			const response = await UserApi.list({ ...debouncedFilters, page });
			setTotalPages(response.totalPages);
			setUsers((prevUsers) => {
				if (page === 1) return response.users;
				return [...prevUsers, ...response.users];
			});
			setHasMore(page < totalPages);
		} catch (error) {
			setHasMore(false);
			Toast({ message: 'Error fetching users', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [debouncedFilters, page, totalPages]);

	useEffect(() => {
		setPage(1);
	}, [debouncedFilters]);

	useEffect(() => {
		fetchUsers();
	}, [fetchUsers]);

	const { loader } = useInfiniteScroll({ loading, hasMore, setPage });

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
	};

	const handleFilterKeyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { value } = e.target;
		setFilters({
			name: '',
			email: '',
			phoneNumber: '',
			role: '',
			gender: ''
		});
		setFilterKey(value);
	};

	return (
		<Container>
			<h2>User List</h2>
			<FilterContainer>
				<Select name="filterKey" onChange={handleFilterKeyChange}>
					<option value="name">Name</option>
					<option value="email">Email</option>
					<option value="phoneNumber">Phone Number</option>
					<option value="gender">Gender</option>
					<option value="role">Role</option>
				</Select>
				{filterKey === 'name' && (
					<Input name="name" placeholder="Name" value={filters.name} onChange={handleFilterChange} />
				)}
				{filterKey === 'email' && (
					<Input name="email" placeholder="Email" value={filters.email} onChange={handleFilterChange} />
				)}
				{filterKey === 'phoneNumber' && (
					<Input
						name="phoneNumber"
						placeholder="Phone Number"
						value={filters.phoneNumber}
						onChange={handleFilterChange}
					/>
				)}
				{filterKey === 'gender' && (
					<Select name="gender" value={filters.gender} onChange={handleFilterChange}>
						<option value="">No Selected</option>
						<option value={UserGender.MALE}>{UserGender.MALE}</option>
						<option value={UserGender.FEMALE}>{UserGender.FEMALE}</option>
					</Select>
				)}
				{filterKey === 'role' && (
					<Select name="role" value={filters.role} onChange={handleFilterChange}>
						<option value="">No Selected</option>
						<option value={UserRole.ADMIN}>{UserRole.ADMIN}</option>
						<option value={UserRole.GYM_MANAGER}>{UserRole.GYM_MANAGER}</option>
						<option value={UserRole.CENTER_STAFF}>{UserRole.CENTER_STAFF}</option>
						<option value={UserRole.CUSTOMER}>{UserRole.CUSTOMER}</option>
						<option value={UserRole.GUEST}>{UserRole.GUEST}</option>
					</Select>
				)}
			</FilterContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableHeader>#</TableHeader>
						<TableHeader>Name</TableHeader>
						<TableHeader>Age</TableHeader>
						<TableHeader>Gender</TableHeader>
						<TableHeader>Phone Number</TableHeader>
						<TableHeader>Email</TableHeader>
						<TableHeader>Role</TableHeader>
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map((user, index) => (
						<UserRow key={user._id} user={user} index={index} onClick={handleRowClick} />
					))}
				</TableBody>
			</Table>
			<div ref={loader} />
			{loading && <Loader />}
		</Container>
	);
};

export default UserList;
