import React from 'react';
import { CircleLoader } from 'react-spinners';
import { styled } from 'styled-components';

const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const Loader: React.FC = () => {
	return (
		<LoaderWrapper>
			<CircleLoader />
		</LoaderWrapper>
	);
};

export default Loader;
