import { ItemCategoryTypes } from 'lib/types';
import React from 'react';
import styled from 'styled-components';

interface CategorySelectProps {
	value: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Select = styled.select`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Option = styled.option``;

const CategorySelect: React.FC<CategorySelectProps> = ({ value, onChange }) => {
	return (
		<>
			<Label htmlFor="category">Category</Label>
			<Select name="category" value={value} onChange={onChange}>
				<Option value="">Not Selected</Option>
				<Option value={ItemCategoryTypes.PT}>pt</Option>
				<Option value={ItemCategoryTypes.CROSSFIT}>crossfit</Option>
				<Option value={ItemCategoryTypes.GOLF}>golf</Option>
				<Option value={ItemCategoryTypes.GX}>gx</Option>
				<Option value={ItemCategoryTypes.PILATES}>pilates</Option>
				<Option value={ItemCategoryTypes.SWIMMING}>swimming</Option>
				<Option value={ItemCategoryTypes.YOGA}>yoga</Option>
				<Option value={ItemCategoryTypes.ZUMBA}>zumba</Option>
			</Select>
		</>
	);
};

export default CategorySelect;
