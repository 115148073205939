import { CenterType, LockerGroupType } from 'lib/types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import Toast, { ToastType } from 'utils/toast';
import styled from 'styled-components';

interface AddLockerGroupModalProps {
	isOpen: boolean;
	center: CenterType;
	onRequestClose: () => void;
	onAddLockerGroup: (lockerGroup: LockerGroupType) => void;
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Button = styled.button`
	padding: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: #007bff;
	color: white;
`;

export const AddLockerGroupModal: React.FC<AddLockerGroupModalProps> = ({
	isOpen,
	center,
	onRequestClose,
	onAddLockerGroup
}) => {
	const [lockerGroup, setLockerGroup] = useState<LockerGroupType>({
		center,
		name: '',
		row: 0,
		quantity: 0
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setLockerGroup((prevLockerGroup) => ({
			...prevLockerGroup,
			[name]: name === 'row' || name === 'quantity' ? parseInt(value, 10) : value
		}));
	};

	const handleAddLockerGroup = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			onAddLockerGroup(lockerGroup);
			Toast({ message: 'Locker Group added successfully', type: ToastType.SUCCESS });
			setLockerGroup({
				center,
				name: '',
				row: 0,
				quantity: 0
			}); // 입력 필드 초기화
			onRequestClose(); // 모달 닫기
		} catch (error) {
			Toast({ message: 'Error adding locker group', type: ToastType.ERROR });
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="Add Locker Group Modal"
			ariaHideApp={false}
			shouldCloseOnEsc
		>
			<h2>Add Locker Group</h2>
			<Form onSubmit={handleAddLockerGroup}>
				<Label htmlFor="name">Name</Label>
				<Input
					id="name"
					name="name"
					type="text"
					value={lockerGroup.name}
					onChange={handleChange}
					placeholder="Locker Group Name"
				/>
				<Label htmlFor="row">Row</Label>
				<Input
					id="row"
					name="row"
					type="number"
					value={lockerGroup.row}
					onChange={handleChange}
					placeholder="Number of Rows"
				/>
				<Label htmlFor="quantity">Quantity</Label>
				<Input
					id="quantity"
					name="quantity"
					type="number"
					value={lockerGroup.quantity}
					onChange={handleChange}
					placeholder="Quantity"
				/>
				<Button type="submit">Add Locker Group</Button>
			</Form>
		</Modal>
	);
};
