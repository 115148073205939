import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertResult } from 'sweetalert2';

export const preventFormSubmissionOnEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
	if (e.key === 'Enter') e.preventDefault();
};

export const calculateAge = (birthDate: string): number => {
	if (!birthDate) return 0;
	const today = new Date();
	const birth = new Date(birthDate);

	let age = today.getFullYear() - birth.getFullYear();
	const monthDiff = today.getMonth() - birth.getMonth();

	if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
		age -= 1;
	}

	return age;
};

export const useDebounce = (value: any, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

export const confirmDelete = async (item: string): Promise<SweetAlertResult<any>> => {
	const result = await Swal.fire({
		title: 'Are you sure?',
		text: `Do you want to delete this ${item}?`,
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Yes, delete it!'
	});
	return result;
};

export const confirmUpdate = async (item: string): Promise<SweetAlertResult<any>> => {
	const result = await Swal.fire({
		title: 'Are you sure?',
		text: `Do you want to update this ${item}?`,
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Yes, update it!'
	});
	return result;
};
