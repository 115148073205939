import { UserType } from 'lib/types';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import UsersTableWithAdd from 'components/common/user/usersTableWithAdd/UsersTableWithAdd';
import { UserApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { useDebounce } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

interface UserSearchModalProps {
	isOpen: boolean;
	defaultUsers: UserType[] | [];
	onRequestClose: () => void;
	onAddUser: (user: UserType) => void;
}

export const UserSearchModal: React.FC<UserSearchModalProps> = ({
	isOpen,
	defaultUsers,
	onRequestClose,
	onAddUser
}) => {
	const [users, setUsers] = useState<UserType[]>([]);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [filters, setFilters] = useState({
		name: ''
	});

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
	};

	const debouncedFilters = useDebounce(filters, 500);

	const fetchUsers = useCallback(async () => {
		if (!isOpen) return;
		setLoading(true);
		try {
			const response = await UserApi.list({ ...debouncedFilters, page });
			setTotalPages(response.totalPages);
			setUsers((prevUsers) => {
				const newUsers = response.users.filter(
					(newUser) => !defaultUsers.some((defaultUser) => defaultUser._id === newUser._id)
				);
				return page === 1 ? newUsers : [...prevUsers, ...newUsers];
			});
			setHasMore(page < totalPages);
		} catch (error) {
			setHasMore(false);
			Toast({ message: 'Error fetching users', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [debouncedFilters, page, totalPages, defaultUsers, setUsers, isOpen]);

	useEffect(() => {
		setPage(1);
	}, [debouncedFilters]);

	useEffect(() => {
		fetchUsers();
	}, [fetchUsers]);

	const { loader } = useInfiniteScroll({ loading, hasMore, setPage });

	const handleAddUser = (user: UserType) => onAddUser(user);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="User Search Modal"
			ariaHideApp={false} // Suppresses the warning
			shouldCloseOnEsc
		>
			<h2>Search Users</h2>
			<button type="button" onClick={onRequestClose}>
				Close
			</button>
			<input name="name" placeholder="Name" value={filters.name} onChange={handleFilterChange} />
			<UsersTableWithAdd users={users} onAddUser={handleAddUser} />
			<div ref={loader} />
			{loading && <Loader />}
		</Modal>
	);
};
