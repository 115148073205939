import { createSlice } from '@reduxjs/toolkit';
import { GymType } from 'lib/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/';

interface gymState {
	gym: GymType | null;
}

const initialState: gymState = {
	gym: null
};

export const slice = createSlice({
	name: 'gym',
	initialState,
	reducers: {
		setGym: (state, { payload }: PayloadAction<GymType>) => {
			state.gym = payload;
		},
		clearGym: (state) => {
			state.gym = null;
		}
	}
});

export const gymName = slice.name;
export const gymReducer = slice.reducer;
export const gymActions = slice.actions;

export const gymSelector = (state: RootState) => state.gym;

export default slice.reducer;
