import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';
import { COLOR } from 'utils/constants/color.constants'; // 이 부분을 수정해야 합니다.

// NotFoundPage 스타일드 컴포넌트
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: ${COLOR.PRIMARY};
`;

const NotFoundWrapper = styled.div`
	background-color: ${COLOR.SECONDARY};
	color: ${COLOR.SECONDARY_FONT};
	padding: 20px;
	border-radius: 8px;
	width: 300px;
	text-align: center;
`;

const StyledTitle = styled.h2`
	font-size: 24px;
	margin-bottom: 20px;
`;

const StyledDescription = styled.p`
	font-size: 16px;
	margin-bottom: 20px;
`;

const Button = styled.button`
	width: 100%;
	padding: 10px;
	background-color: ${COLOR.ACCENT};
	color: ${COLOR.ACCENT_FONT};
	border: none;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: ${COLOR.BACKGROUND};
		color: ${COLOR.BACKGROUND_FONT};
	}
`;

const NotFoundPage: React.FC = () => {
	const navigate = useNavigate();

	const handleGoHome = () => {
		navigate(ROUTE_NAMES.HOME);
	};

	return (
		<Wrapper>
			<NotFoundWrapper>
				<StyledTitle>Page Not Found</StyledTitle>
				<StyledDescription>The page you are looking for does not exist.</StyledDescription>
				<Button onClick={handleGoHome}>Go to Home</Button>
			</NotFoundWrapper>
		</Wrapper>
	);
};

export default NotFoundPage;
