import { ItemTypes } from 'lib/types';
import React from 'react';
import styled from 'styled-components';

interface SubscriptionTypeSelectProps {
	value: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Select = styled.select`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Option = styled.option``;

const TypeSelect: React.FC<SubscriptionTypeSelectProps> = ({ value, onChange }) => {
	return (
		<>
			<Label htmlFor="type">Type</Label>
			<Select name="type" value={value} onChange={onChange}>
				<Option value="">Not Selected</Option>
				<Option value={ItemTypes.ONCE}>Once</Option>
				<Option value={ItemTypes.SUBSCRIPTION}>Subscription</Option>
				<Option value={ItemTypes.ITEM}>Item</Option>
			</Select>
		</>
	);
};

export default TypeSelect;
