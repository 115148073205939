import React from 'react';
import styled from 'styled-components';
import { AuthApi } from 'lib';
import Cookies from 'js-cookie';
import { ROUTE_NAMES } from 'utils/constants/route.constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/reducers/user';
import { gymsActions } from 'store/reducers/gyms';
import { centersActions } from 'store/reducers/centers';
import Toast, { ToastType } from 'utils/toast';

const SignOutButton = styled.button`
	margin-top: 20px;
	padding: 10px 20px;
	background-color: #ff6f61;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #e6554d;
	}
`;

const SignOutBtn: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleSignOut = async () => {
		try {
			await AuthApi.signOut();
			dispatch(userActions.signOut());
			dispatch(gymsActions.clearGyms());
			dispatch(centersActions.clearCenters());
			Cookies.remove('token');
			Toast({ message: 'Successfully signed out.', type: ToastType.SUCCESS });
			navigate(ROUTE_NAMES.SIGN_IN);
		} catch (error) {
			Toast({ message: 'Error signing out. Please try again.', type: ToastType.ERROR });
		}
	};

	return <SignOutButton onClick={handleSignOut}>Sign Out</SignOutButton>;
};

export default SignOutBtn;
