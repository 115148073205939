import React from 'react';
import styled from 'styled-components';
import { UserType } from 'lib/types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
`;

const Tr = styled.tr`
	&:nth-child(odd) {
		background-color: #fff;
	}
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	&:hover {
		background-color: #e9ecef; /* Light gray background on hover */
	}
`;

const Td = styled.td`
	border: 1px solid #ddd;
	padding: 8px;
	cursor: pointer;
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: red;
`;

const UsersTableWithDelete: React.FC<{ users: UserType[]; onRemoveUser: (user: UserType) => void }> = ({
	users,
	onRemoveUser
}) => {
	const navigate = useNavigate();
	const handleRemoveUser = (user: UserType) => {
		onRemoveUser(user);
	};

	const onClickUser = (user: UserType) => navigate(`${ROUTE_NAMES.USER}/${user._id}`);

	return (
		<Table>
			<thead>
				<tr>
					<Th>Name</Th>
					<Th>Email</Th>
					<Th>Phone Number</Th>
					<Th>Delete</Th>
				</tr>
			</thead>
			<tbody>
				{users.map((user) => (
					<Tr key={user._id}>
						<Td onClick={() => onClickUser(user)}>{user.name}</Td>
						<Td onClick={() => onClickUser(user)}>{user.email}</Td>
						<Td onClick={() => onClickUser(user)}>{user.phoneNumber}</Td>
						<Td>
							<Button onClick={() => handleRemoveUser(user)}>x</Button>
						</Td>
					</Tr>
				))}
			</tbody>
		</Table>
	);
};

export default UsersTableWithDelete;
