import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/constants/route.constants';
import { Main } from 'components/common';
import LockerList from 'page/locker/list/LockerList';
import LockerDetail from 'page/locker/detail/LockerDetail';

const Locker: React.FC = () => {
	return (
		<Main>
			<Routes>
				<Route path={ROUTE_PATHS.LIST} element={<LockerList />} />
				<Route path={ROUTE_PATHS.DETAIL} element={<LockerDetail />} />
			</Routes>
		</Main>
	);
};

export default Locker;
