import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { COLOR } from 'utils/constants/color.constants';
import { getSocialLoginIcon } from 'assets/images';
import { AuthApi, CenterApi, GymApi, UserApi } from 'lib';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/reducers/user';
import Cookies from 'js-cookie';
import { CenterType, SocialLoginTypes, UserRole } from 'lib/types';
import Toast, { ToastType } from 'utils/toast';
import { gymsActions } from 'store/reducers/gyms';
import { centersActions } from 'store/reducers/centers';
import { gymActions } from 'store/reducers/gym';
import { centerActions } from 'store/reducers/center';

// 로그인 폼 스타일드 컴포넌트
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: ${COLOR.PRIMARY};
`;

const LoginFormWrapper = styled.div`
	background-color: ${COLOR.SECONDARY};
	color: ${COLOR.SECONDARY_FONT};
	padding: 20px;
	border-radius: 8px;
	width: 300px;
`;

const StyledTitle = styled.h2`
	font-size: 24px;
	margin-bottom: 20px;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
`;

const Input = styled.input`
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid ${COLOR.ACCENT};
	border-radius: 4px;
	color: ${COLOR.ACCENT_FONT};
`;

const Button = styled.button`
	width: 100%;
	padding: 10px;
	background-color: ${COLOR.ACCENT};
	color: ${COLOR.ACCENT_FONT};
	border: none;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: ${COLOR.BACKGROUND};
		color: ${COLOR.BACKGROUND_FONT};
	}
	&:last-child {
		margin-top: 10px;
	}
`;

const SocialLoginIcons = styled.div`
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
`;

const rotateAnimation = keyframes`
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(-2px);
	}
	50% {
		transform: translateX(2px);
	}
	75% {
		transform: translateX(-2px);
	}
	100% {
		transform: translateX(0);
	}
`;

const SocialLoginIcon = styled.img`
	width: 30px;
	height: 30px;
	cursor: pointer;
	&:hover {
		animation: ${rotateAnimation} 1s infinite linear;
	}
`;

const SignIn: React.FC = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleRole = async (role: UserRole) => {
		switch (role) {
			case UserRole.GUEST || UserRole.CUSTOMER:
				Cookies.remove('token');
				break;
			case UserRole.GYM_MANAGER: {
				const gymMe = await GymApi.me();
				dispatch(gymsActions.setGyms(gymMe.gyms));
				dispatch(gymActions.setGym(gymMe.gyms[0]));
				const centers = gymMe.gyms
					.flatMap((gym) => gym.centers)
					.filter((center): center is CenterType => center !== undefined) as CenterType[];
				if (centers.length > 0) {
					dispatch(centersActions.setCenters(centers));
					dispatch(centerActions.setCenter(centers[0]));
				}
				break;
			}
			case UserRole.CENTER_STAFF: {
				const centerMe = await CenterApi.me();
				dispatch(centersActions.setCenters(centerMe.centers));
				dispatch(centerActions.setCenter(centerMe.centers[0]));
				break;
			}
			default:
				break;
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await AuthApi.signIn({ email, password });
			const userMe = await UserApi.me();
			dispatch(userActions.signIn(userMe.user));
			handleRole(userMe.user.role as UserRole);
			Toast({ message: '로그인 성공', type: ToastType.SUCCESS });
			navigate(ROUTE_NAMES.HOME);
		} catch (err) {
			Toast({ message: '아이디 또는 비밀번호가 맞지 않습니다.', type: ToastType.ERROR });
		}
	};

	// 추가된 코드: 회원가입 버튼을 클릭할 때 이동하는 함수
	const handleSignUpClick = () => {
		navigate(ROUTE_NAMES.SIGN_UP);
	};

	return (
		<Wrapper>
			<LoginFormWrapper>
				<StyledTitle>Gymmoa Sign In</StyledTitle>
				<StyledForm>
					<Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
					<Input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Button onClick={handleSubmit}>Submit</Button>
					<Button onClick={handleSignUpClick}>Sign Up</Button>
				</StyledForm>
				<SocialLoginIcons>
					{Object.values(SocialLoginTypes).map((socialType) => (
						<SocialLoginIcon key={socialType} src={getSocialLoginIcon(socialType)} />
					))}
				</SocialLoginIcons>
			</LoginFormWrapper>
		</Wrapper>
	);
};

export default SignIn;
