// src/App.tsx
import React, { useState } from 'react';
import DateSelector from 'components/common/dateSelector/DateSelector';
import LessonTable from 'components/common/lessonTable/LessonTable';

const LessonList: React.FC = () => {
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const instructors = ['김산하', '김정원', '김로빈', '김지훈', '김민재', '김민규'];

	// 예시 강의 데이터
	const lessons = {
		김산하: { 9: 'Math Lesson', 13: 'Physics Lesson' },
		김정원: { 10: 'Chemistry Lesson', 14: 'Biology Lesson' },
		김로빈: { 11: 'History Lesson', 15: 'Geography Lesson' },
		김지훈: { 12: 'Music Lesson', 16: 'Art Lesson' },
		김민재: { 17: 'Music Lesson', 18: 'Art Lesson' },
		김민규: { 12: 'English Lesson', 16: 'Korean Lesson' }
	};

	return (
		<>
			<DateSelector selectedDate={selectedDate} onDateChange={setSelectedDate} />
			<LessonTable instructors={instructors} lessons={lessons} />
		</>
	);
};

export default LessonList;
