import React from 'react';
import styled from 'styled-components';

const Indicator = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	height: 1px;
	background-color: red;
	transform: translateY(-1px);
`;

interface CurrentTimeIndicatorProps {
	top: number;
}

const CurrentTimeIndicator: React.FC<CurrentTimeIndicatorProps> = ({ top }) => {
	return <Indicator style={{ top: `${top}%` }} />;
};

export default CurrentTimeIndicator;
