import { createSlice } from '@reduxjs/toolkit';
import { GymType } from 'lib/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/';

interface gymsState {
	gyms: GymType[] | null;
}

const initialState: gymsState = {
	gyms: null
};

export const slice = createSlice({
	name: 'gyms',
	initialState,
	reducers: {
		setGyms: (state, { payload }: PayloadAction<GymType[]>) => {
			state.gyms = payload;
		},
		clearGyms: (state) => {
			state.gyms = null;
		}
	}
});

export const gymsName = slice.name;
export const gymsReducer = slice.reducer;
export const gymsActions = slice.actions;

export const gymsSelector = (state: RootState) => state.gyms;

export default slice.reducer;
