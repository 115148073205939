import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
import Toast, { ToastType } from 'utils/toast';

// Kakao API 초기화
const { kakao } = window;

interface AddressSearchModalProps {
	isOpen: boolean;
	onRequestClose: () => void;
	onAddressSelected: (address: string, location: { latitude: number; longitude: number }) => void; // 좌표 추가
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Button = styled.button`
	padding: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	background-color: #007bff;
	color: white;
	margin-top: 10px;
`;

const AddressSearchModal: React.FC<AddressSearchModalProps> = ({ isOpen, onRequestClose, onAddressSelected }) => {
	const [searchedAddress, setSearchedAddress] = useState('');
	const [additionalAddress, setAdditionalAddress] = useState('');
	const [geoLocation, setGeoLocation] = useState({ latitude: 0, longitude: 0 });

	useEffect(() => {
		if (searchedAddress === '') {
			return;
		}
		kakao.maps.load(() => {
			const geocoder = new kakao.maps.services.Geocoder();
			geocoder.addressSearch(searchedAddress, (result: any, status: any) => {
				if (status === kakao.maps.services.Status.OK) {
					const { y, x } = result[0];
					setGeoLocation({ latitude: y, longitude: x });
				} else {
					Toast({ message: 'Failed to find location', type: ToastType.ERROR });
				}
			});
		});
	}, [searchedAddress]);

	const handleComplete = (data: any) => {
		let fullAddress = data.address;
		let extraAddress = '';

		if (data.addressType === 'R') {
			if (data.bname !== '') {
				extraAddress += data.bname;
			}
			if (data.buildingName !== '') {
				extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
			}
			fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
		}
		setSearchedAddress(fullAddress);
	};

	const handleAdditionalAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdditionalAddress(e.target.value);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		const fullAddress = `${searchedAddress} ${additionalAddress}`;
		onAddressSelected(fullAddress, geoLocation);
		onRequestClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="Address Search Modal"
			ariaHideApp={false}
			shouldCloseOnEsc
		>
			<h2>Search Address</h2>
			{isOpen && <DaumPostcode onComplete={handleComplete} />}
			{searchedAddress && (
				<Form onSubmit={handleSubmit}>
					<Label>Searched Address</Label>
					<Input type="text" value={searchedAddress} readOnly />
					<Label>Additional Address</Label>
					<Input type="text" value={additionalAddress} onChange={handleAdditionalAddressChange} />
					<Button type="submit">Complete</Button>
				</Form>
			)}
			<Button onClick={onRequestClose}>Close</Button>
		</Modal>
	);
};

export default AddressSearchModal;
