import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CenterType, GymDetailType, UserType } from 'lib/types';
import { GymApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { preventFormSubmissionOnEnter } from 'utils/function';
import { UserSearchModal } from 'components/common/user/userSearchModal/UserSearchModal';
import { CenterSearchModal } from 'components/common/center/centerSearchModal/CenterSearchModal';
import CentersTableWithDelete from 'components/common/center/centersTableWithDelete/CentersTableWithDelete';
import UsersTableWithDelete from 'components/common/user/usersTableWithDelete/UsersTableWithDelete';
import Loader from 'components/common/loader/Loader';

const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const GymWrite: React.FC = () => {
	const navigate = useNavigate();
	const [gym, setGym] = useState<Partial<GymDetailType>>({});
	const [loading, setLoading] = useState(false);
	const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
	const [isCentersModalOpen, setIsCentersModalOpen] = useState(false);

	const openMembersModal = () => setIsMembersModalOpen(true);
	const closeMembersModal = () => setIsMembersModalOpen(false);

	const openCentersModal = () => setIsCentersModalOpen(true);
	const closeCentersModal = () => setIsCentersModalOpen(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setGym((prevGym) => ({ ...prevGym, [name]: value }));
	};

	const handleAddManager = (user: UserType) => {
		setGym((prevGym) => {
			const managers = prevGym.managers || [];
			return { ...prevGym, managers: [...managers, user] };
		});
	};

	const handleRemoveManager = (manager: UserType) => {
		setGym((prevGym) => {
			const managers = prevGym.managers || [];
			const updatedManagers = managers.filter((existingManager) => existingManager._id !== manager._id);
			return { ...prevGym, managers: updatedManagers };
		});
	};

	const handleAddCenter = (center: CenterType) => {
		setGym((prevGym) => {
			const centers = prevGym.centers || [];
			return { ...prevGym, centers: [...centers, center] };
		});
	};

	const handleRemoveCenter = (center: CenterType) => {
		setGym((prevGym) => {
			const centers = prevGym.centers || [];
			const updatedCenters = centers.filter((existingCenter) => existingCenter._id !== center._id);
			return { ...prevGym, centers: updatedCenters };
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);
		try {
			await GymApi.create(gym);
			Toast({ message: 'Gym created successfully', type: ToastType.SUCCESS });
			navigate(-1);
		} catch (error) {
			Toast({ message: 'Error creating gym', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loader />;

	return (
		<Container>
			<h2>Write Gym</h2>
			<Form onSubmit={handleSubmit} onKeyDown={preventFormSubmissionOnEnter}>
				<Label>Name</Label>
				<Input name="name" value={gym?.name} onChange={handleChange} />
				<FlexContainer>
					<Label>Managers</Label>
					<Button type="button" onClick={openMembersModal}>
						Search Users
					</Button>
				</FlexContainer>
				<UsersTableWithDelete users={gym?.managers || []} onRemoveUser={handleRemoveManager} />

				<FlexContainer>
					<Label>Centers</Label>
					<Button type="button" onClick={openCentersModal}>
						Search Centers
					</Button>
				</FlexContainer>
				<CentersTableWithDelete centers={gym?.centers || []} onRemoveCenter={handleRemoveCenter} />
				<ButtonContainer>
					<Button type="submit">Submit</Button>
				</ButtonContainer>
			</Form>
			<UserSearchModal
				isOpen={isMembersModalOpen}
				defaultUsers={gym?.managers || []}
				onRequestClose={closeMembersModal}
				onAddUser={handleAddManager}
			/>
			<CenterSearchModal
				isOpen={isCentersModalOpen}
				defaultCenters={gym?.centers || []}
				onRequestClose={closeCentersModal}
				onAddCenter={handleAddCenter}
			/>
		</Container>
	);
};

export default GymWrite;
