import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { GymType, UserRole } from 'lib/types';
import { GymApi } from 'lib';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES, ROUTE_PATHS } from 'utils/constants/route.constants';
import Toast, { ToastType } from 'utils/toast';
import { useDebounce } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import { useSelector } from 'react-redux';
import { gymsSelector } from 'store/reducers/gyms';
import { userSelector } from 'store/reducers/user';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const AddButton = styled.button`
	height: 40px;
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
`;

const Input = styled.input`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Select = styled.select`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const TableHead = styled.thead`
	background-color: #f8f9fa;
`;

const TableBody = styled.tbody`
	background-color: #fff;
`;

const TableRow = styled.tr`
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	cursor: pointer;
`;

const TableHeader = styled.th`
	padding: 10px;
	text-align: left;
	border-bottom: 1px solid #dee2e6;
`;

const TableCell = styled.td`
	padding: 10px;
	border-bottom: 1px solid #dee2e6;
`;

const GymList: React.FC = () => {
	const [gyms, setGyms] = useState<GymType[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [filterKey, setFilterKey] = useState('name');
	const [hasMore, setHasMore] = useState(true);
	const [filters, setFilters] = useState({
		name: ''
	});
	const navigate = useNavigate();
	const { user } = useSelector(userSelector);
	const { gyms: userGyms } = useSelector(gymsSelector);

	const handleRowClick = (gymId: string | undefined) => {
		navigate(`${ROUTE_NAMES.GYM}/${gymId}`); // 디테일 페이지로 이동
	};

	const debouncedFilters = useDebounce(filters, 500);

	const fetchGyms = useCallback(async () => {
		setLoading(true);
		try {
			if (user?.role === UserRole.CENTER_STAFF) return;
			if (user?.role === UserRole.GYM_MANAGER && userGyms) {
				setGyms(userGyms);
				setHasMore(false);
			} else if (user?.role === UserRole.ADMIN) {
				const response = await GymApi.list({ ...debouncedFilters, page });
				setTotalPages(response.totalPages);
				setGyms((prevGyms) => {
					if (page === 1) return response.gyms;
					return [...prevGyms, ...response.gyms];
				});
				setHasMore(page < totalPages);
			}
		} catch (error) {
			setHasMore(false);
			Toast({ message: 'Error fetching gyms', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [debouncedFilters, page, totalPages, user, userGyms]);

	useEffect(() => {
		setPage(1);
	}, []);

	useEffect(() => {
		fetchGyms();
	}, [fetchGyms]);

	const { loader } = useInfiniteScroll({ loading, hasMore, setPage });

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
	};

	const handleFilterKeyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { value } = e.target;
		setFilters({
			name: ''
		});
		setFilterKey(value);
	};

	return (
		<Container>
			<ButtonWrapper>
				<h2>Gym List</h2>
				{user?.role === UserRole.ADMIN && (
					<AddButton onClick={() => navigate(`${ROUTE_NAMES.GYM}${ROUTE_PATHS.WRITE}`)}>Add Gym</AddButton>
				)}
			</ButtonWrapper>
			<FilterContainer>
				<Select name="filterKey" onChange={handleFilterKeyChange} value={filterKey}>
					<option value="name">Name</option>
				</Select>
				{filterKey === 'name' && (
					<Input name="name" placeholder="Name" value={filters.name} onChange={handleFilterChange} />
				)}
			</FilterContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableHeader>#</TableHeader>
						<TableHeader>Name</TableHeader>
						<TableHeader>Managers</TableHeader>
						<TableHeader>Centers</TableHeader>
					</TableRow>
				</TableHead>
				<TableBody>
					{gyms.map((gym, index) => (
						<TableRow key={gym._id} onClick={() => handleRowClick(gym._id)}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{gym.name}</TableCell>
							<TableCell>{gym.managers?.length}</TableCell>
							<TableCell>{gym.centers?.length}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div ref={loader} />
			{loading && <Loader />}
		</Container>
	);
};

export default GymList;
