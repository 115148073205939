import { combineReducers } from 'redux';
import user from 'store/reducers/user';
import gyms from 'store/reducers/gyms';
import gym from 'store/reducers/gym';
import centers from 'store/reducers/centers';
import center from 'store/reducers/center';

const rootReducer = combineReducers({
	user,
	gyms,
	gym,
	centers,
	center
});

export default rootReducer;
