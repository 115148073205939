import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { CenterFacility, UserType, CenterDetailType } from 'lib/types';
import { CenterApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { useNavigate } from 'react-router-dom';
import { preventFormSubmissionOnEnter } from 'utils/function';
import { getFacilityIcon } from 'assets/images';
import { UserSearchModal } from 'components/common/user/userSearchModal/UserSearchModal';
import UsersTableWithDelete from 'components/common/user/usersTableWithDelete/UsersTableWithDelete';
import Loader from 'components/common/loader/Loader';
import AddressSearchModal from 'components/common/addressSearchModal/AddressSearchModal';
import KakaoMap from 'components/common/kakaoMap/KakaoMap';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;

const CheckboxLabel = styled.label`
	margin-right: 20px;
	display: flex;
	align-items: center;
`;

const IconImage = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 5px;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const CenterWrite: React.FC = () => {
	const [center, setCenter] = useState<Partial<CenterDetailType>>({});
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
	const navigate = useNavigate();

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	const openAddressModal = () => setIsAddressModalOpen(true);
	const closeAddressModal = () => setIsAddressModalOpen(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setCenter((prevCenter) => ({ ...prevCenter, [name]: value }));
	};

	const handleFacilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.target;
		if (checked) {
			setCenter((prevCenter) => ({
				...prevCenter,
				facilities: [...(prevCenter.facilities || []), value as CenterFacility]
			}));
		} else {
			setCenter((prevCenter) => ({
				...prevCenter,
				facilities: (prevCenter.facilities || []).filter((facility) => facility !== value)
			}));
		}
	};

	const handleRemoveStaffMembers = (user: UserType) => {
		setCenter((prevCenter) => {
			const staffMembers = prevCenter.staffMembers || [];
			const updatedStaffMembers = staffMembers.filter((existingStaffManager) => existingStaffManager._id !== user._id);
			return { ...prevCenter, staffMembers: updatedStaffMembers };
		});
	};

	const handleAddStaffMembers = useCallback((user: UserType) => {
		setCenter((prevCenter) => {
			const staffMembers = prevCenter.staffMembers || [];
			const isExistingUser = staffMembers.some((existingStaffManager) => existingStaffManager._id === user._id);
			if (isExistingUser) return prevCenter;
			return { ...prevCenter, staffMembers: [...staffMembers, user] };
		});
	}, []);

	const handleAddressSelected = (address: string, location: { latitude: number; longitude: number }) => {
		setCenter((prevCenter) => (prevCenter ? { ...prevCenter, address, location } : {}));
		closeAddressModal();
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);
		try {
			await CenterApi.create(center);
			Toast({ message: 'Center created successfully', type: ToastType.SUCCESS });
			navigate(-1);
		} catch (error) {
			Toast({ message: 'Error creating center', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loader />;

	return (
		<Container>
			<h2>Write Center</h2>
			<Form onSubmit={handleSubmit} onKeyDown={preventFormSubmissionOnEnter}>
				<Label>Name</Label>
				<Input name="name" value={center.name} onChange={handleChange} />

				<FlexContainer>
					<Label>Address</Label>
					<Button type="button" onClick={openAddressModal}>
						Search Address
					</Button>
				</FlexContainer>
				<Input name="address" value={center.address || ''} onChange={handleChange} />
				{center.location && <KakaoMap location={center.location} />}
				<Label>Phone Number</Label>
				<Input name="phoneNumber" value={center.phoneNumber} onChange={handleChange} />

				<Label>Facilities</Label>
				<CheckboxContainer>
					{Object.values(CenterFacility).map((facility) => (
						<CheckboxLabel key={facility}>
							<input
								type="checkbox"
								value={facility}
								checked={(center.facilities || []).includes(facility)}
								onChange={handleFacilityChange}
							/>
							<IconImage src={getFacilityIcon(facility)} alt={facility} />
							{facility}
						</CheckboxLabel>
					))}
				</CheckboxContainer>

				<Label>Operating Time</Label>
				<Input name="operatingTime" value={center.operatingTime} onChange={handleChange} />

				<FlexContainer>
					<Label>Staff Members</Label>
					<Button type="button" onClick={openModal}>
						Search Users
					</Button>
				</FlexContainer>
				<UsersTableWithDelete users={center.staffMembers || []} onRemoveUser={handleRemoveStaffMembers} />

				<ButtonContainer>
					<Button type="submit">Submit</Button>
				</ButtonContainer>
			</Form>
			<AddressSearchModal
				isOpen={isAddressModalOpen}
				onRequestClose={closeAddressModal}
				onAddressSelected={handleAddressSelected}
			/>
			<UserSearchModal
				isOpen={isModalOpen}
				defaultUsers={center.staffMembers || []}
				onRequestClose={closeModal}
				onAddUser={handleAddStaffMembers}
			/>
		</Container>
	);
};

export default CenterWrite;
