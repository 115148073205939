import React, { useCallback, useEffect, useState } from 'react';
import { CenterType, LockerGroupType, LockerType } from 'lib/types';
import Toast, { ToastType } from 'utils/toast';
import { LockerGroupApi } from 'lib';
import { centerSelector } from 'store/reducers/center';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ROUTE_NAMES } from 'utils/constants/route.constants';
import { useNavigate } from 'react-router-dom';
import { AddLockerGroupModal } from 'components/common/locker/addLockerGroupModal/AddLockerGroupModal';
import LockerGrid from 'components/common/locker/lockerGrid/LockerGrid';
import Loader from 'components/common/loader/Loader';

const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const AddButton = styled.button`
	height: 40px;
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid white;
	border-radius: 5px;
	padding: 10px;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Select = styled.select`
	margin-bottom: 10px;
`;

const LockerList: React.FC = () => {
	const [lockerGroups, setLockerGroups] = useState<LockerGroupType[]>([]);
	const [selectedLockerGroup, setSelectedLockerGroup] = useState<LockerGroupType | undefined>();
	const [loading, setLoading] = useState(false);
	const [lockerGroupModalOpen, setLockerGroupModalOpen] = useState(false);
	const { center } = useSelector(centerSelector);
	const navigate = useNavigate();

	const handleLockerClick = (locker: LockerType) => navigate(`${ROUTE_NAMES.LOCKER}/${locker._id}`);

	const handleLockerGroupModalOpen = () => setLockerGroupModalOpen(true);
	const handleLockerGroupModalClose = () => setLockerGroupModalOpen(false);

	const handleLockerGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const SLG = lockerGroups.find((lg) => lg._id === e.target.value);
		setSelectedLockerGroup(SLG);
	};

	const fetchLockers = useCallback(async () => {
		setLoading(true);
		try {
			const lockerGroupRes = await LockerGroupApi.list({
				center: center?._id,
				populateLockers: true,
				populateCenter: true
			});
			setLockerGroups(lockerGroupRes.lockerGroups);
			setSelectedLockerGroup(lockerGroupRes.lockerGroups[0]);
		} catch (error) {
			Toast({ message: 'Error fetching lockers', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [center]);

	const handleAddLockerGroup = useCallback(
		async (newLockerGroup: LockerGroupType) => {
			try {
				await LockerGroupApi.create(newLockerGroup);
				Toast({ message: 'Locker group added successfully', type: ToastType.SUCCESS });
				await fetchLockers();
			} catch (error) {
				Toast({ message: 'Error adding locker group', type: ToastType.ERROR });
			}
		},
		[fetchLockers]
	);

	useEffect(() => {
		fetchLockers();
	}, [fetchLockers]);

	return (
		<Container>
			<ButtonWrapper>
				<h2>Locker List</h2>
				<SelectContainer>
					<Label>Locker Group Select</Label>
					<Select value={selectedLockerGroup?._id} onChange={handleLockerGroupChange}>
						<option value="">--Select a Gym--</option>
						{lockerGroups?.map((lg) => (
							<option key={lg._id} value={lg._id}>
								{lg.center.name} {lg.name} - {lg.quantity} Lockers
							</option>
						))}
					</Select>
				</SelectContainer>
				<AddButton onClick={handleLockerGroupModalOpen}>Add Locker Group</AddButton>
				<AddLockerGroupModal
					isOpen={lockerGroupModalOpen}
					center={center as CenterType}
					onRequestClose={handleLockerGroupModalClose}
					onAddLockerGroup={handleAddLockerGroup} // Pass the function to handle adding locker group
				/>
			</ButtonWrapper>
			{selectedLockerGroup && <LockerGrid lockerGroup={selectedLockerGroup} onLockerClick={handleLockerClick} />}
			{loading && <Loader />}
		</Container>
	);
};

export default LockerList;
