import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'utils/constants/color.constants';
import { SignOutBtn } from 'components/common';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';

const HeaderContainer = styled.header`
	width: calc(100% - 200px);
	height: 60px;
	background-color: ${COLOR.PRIMARY};
	color: ${COLOR.PRIMARY_FONT};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 200px;
`;

const Title = styled.h1`
	margin: 0;
`;

const Button = styled.button`
	background-color: ${COLOR.SECONDARY};
	color: ${COLOR.SECONDARY_FONT};
	border: none;
	padding: 10px 15px;
	cursor: pointer;
	border-radius: 5px;

	&:hover {
		background-color: ${COLOR.BACKGROUND};
	}
`;

const Header: React.FC = () => {
	const navigate = useNavigate();

	const handleMyPage = () => {
		navigate(ROUTE_NAMES.USER_MY_PAGE);
	};

	return (
		<HeaderContainer>
			<Title>Dashboard Header</Title>
			<Button onClick={handleMyPage}>마이페이지</Button>
			<SignOutBtn />
		</HeaderContainer>
	);
};

export default Header;
