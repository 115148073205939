import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LockerApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { LockerType, LockerEnumStatus } from 'lib/types';
import Loader from 'components/common/loader/Loader';

const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Select = styled.select`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Option = styled.option``;

const TextArea = styled.textarea`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	resize: vertical;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const LockerDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [locker, setLocker] = useState<LockerType | null>(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchLocker = async () => {
			setLoading(true);
			try {
				const { locker: fetchedLocker } = await LockerApi.detail(id);
				setLocker(fetchedLocker);
			} catch (error) {
				Toast({ message: 'Error fetching locker details', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		};
		fetchLocker();
	}, [id]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setLocker((prevLocker) => ({
			...prevLocker,
			[name]: value
		}));
	};

	const handleUpdate = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			delete locker?._id;
			delete locker?.createdAt;
			delete locker?.updatedAt;
			await LockerApi.update(id, locker);
			Toast({ message: 'Locker updated successfully', type: ToastType.SUCCESS });
			navigate(-1);
		} catch (error) {
			Toast({ message: 'Error updating locker', type: ToastType.ERROR });
		}
	};

	return (
		<Container>
			<h2>Locker Detail</h2>
			{locker && (
				<Form onSubmit={handleUpdate}>
					<Label>Number</Label>
					<Input name="number" type="number" value={locker.number || ''} onChange={handleChange} />

					<Label>Status</Label>
					<Select name="status" value={locker.status || ''} onChange={handleChange}>
						<Option value={LockerEnumStatus.InUse}>In Use</Option>
						<Option value={LockerEnumStatus.Available}>Available</Option>
						<Option value={LockerEnumStatus.Unavailable}>Unavailable</Option>
						<Option value={LockerEnumStatus.ExpiringSoon}>Expiring Soon</Option>
						<Option value={LockerEnumStatus.Expired}>Expired</Option>
					</Select>

					<Label>Memo</Label>
					<TextArea name="memo" value={locker.memo || ''} onChange={handleChange} rows={5} />

					<Label>Start Date</Label>
					<Input name="startDate" type="date" value={locker.startDate || ''} onChange={handleChange} />

					<Label>End Date</Label>
					<Input name="endDate" type="date" value={locker.endDate || ''} onChange={handleChange} />

					<ButtonContainer>
						<Button type="submit">Update</Button>
					</ButtonContainer>
				</Form>
			)}
			{loading && <Loader />}
		</Container>
	);
};

export default LockerDetail;
