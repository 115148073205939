import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { CenterMembershipType, UserRole } from 'lib/types';
import { CenterMembershipApi } from 'lib';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';
import Toast, { ToastType } from 'utils/toast';
import { useDebounce } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import { useSelector } from 'react-redux';
import { centerSelector } from 'store/reducers/center';
import { userSelector } from 'store/reducers/user';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
`;

const Input = styled.input`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Select = styled.select`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const TableHead = styled.thead`
	background-color: #f8f9fa;
`;

const TableBody = styled.tbody`
	background-color: #fff;
`;

const TableRow = styled.tr`
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	cursor: pointer;
`;

const TableHeader = styled.th`
	padding: 10px;
	text-align: left;
	border-bottom: 1px solid #dee2e6;
`;

const TableCell = styled.td`
	padding: 10px;
	border-bottom: 1px solid #dee2e6;
`;

const CenterMembershipList: React.FC = () => {
	const [centerMemberships, setCenterMemberships] = useState<CenterMembershipType[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [filterKey, setFilterKey] = useState('userName');
	const [hasMore, setHasMore] = useState(true);
	const [filters, setFilters] = useState({
		userName: '',
		userPhoneNumber: '',
		populateCenter: true,
		populateUser: true
	});
	const navigate = useNavigate();
	const { center } = useSelector(centerSelector);
	const { user } = useSelector(userSelector);

	const handleRowClick = (centerMembershipId: string | undefined) => {
		navigate(`${ROUTE_NAMES.CENTER_MEMBERSHIP}/${centerMembershipId}`); // 디테일 페이지로 이동
	};

	const debouncedFilters = useDebounce(filters, 500);

	const fetchCenterMemberships = useCallback(async () => {
		setLoading(true);
		try {
			if (user?.role === UserRole.ADMIN) {
				const response = await CenterMembershipApi.list({
					...debouncedFilters,
					page
				});
				setTotalPages(response.totalPages);
				setCenterMemberships((prevCenterMemberships) => {
					if (page === 1) return response.centerMemberships;
					return [...prevCenterMemberships, ...response.centerMemberships];
				});
				setHasMore(page < totalPages);
			} else if (center) {
				const response = await CenterMembershipApi.list({
					...debouncedFilters,
					page,
					center: center._id
				});
				setTotalPages(response.totalPages);
				setCenterMemberships((prevCenterMemberships) => {
					if (page === 1) return response.centerMemberships;
					return [...prevCenterMemberships, ...response.centerMemberships];
				});
				setHasMore(page < totalPages);
			}
		} catch (error) {
			setHasMore(false);
			Toast({ message: 'Error fetching centerMemberships', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [debouncedFilters, page, totalPages, center, user]);

	useEffect(() => {
		setPage(1);
	}, [center]);

	useEffect(() => {
		fetchCenterMemberships();
	}, [fetchCenterMemberships]);

	const { loader } = useInfiniteScroll({ loading, hasMore, setPage });

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
	};

	const handleFilterKeyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { value } = e.target;
		setFilters({
			userName: '',
			userPhoneNumber: '',
			populateCenter: true,
			populateUser: true
		});
		setFilterKey(value);
	};

	return (
		<Container>
			<ButtonWrapper>
				<h2>CenterMembership List</h2>
			</ButtonWrapper>
			<FilterContainer>
				<Select name="filterKey" onChange={handleFilterKeyChange} value={filterKey}>
					<option value="userName">Name</option>
					<option value="userPhoneNumber">PhoneNumber</option>
				</Select>
				{filterKey === 'userName' && (
					<Input name="userName" placeholder="User Name" value={filters.userName} onChange={handleFilterChange} />
				)}
				{filterKey === 'userPhoneNumber' && (
					<Input
						name="userPhoneNumber"
						placeholder="User PhoneNumber"
						value={filters.userPhoneNumber}
						onChange={handleFilterChange}
					/>
				)}
			</FilterContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableHeader>#</TableHeader>
						<TableHeader>Center</TableHeader>
						<TableHeader>User Name</TableHeader>
						<TableHeader>User Gender</TableHeader>
						<TableHeader>User PhoneNumber</TableHeader>
					</TableRow>
				</TableHead>
				<TableBody>
					{centerMemberships.map((centerMembership, index) => (
						<TableRow key={centerMembership._id} onClick={() => handleRowClick(centerMembership._id)}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{centerMembership?.center?.name}</TableCell>
							<TableCell>{centerMembership?.user?.name}</TableCell>
							<TableCell>{centerMembership?.user?.gender}</TableCell>
							<TableCell>{centerMembership?.user?.phoneNumber}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div ref={loader} />
			{loading && <Loader />}
		</Container>
	);
};

export default CenterMembershipList;
