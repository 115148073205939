import { createSlice } from '@reduxjs/toolkit';
import { CenterType } from 'lib/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/';

interface centersState {
	centers: CenterType[] | null;
}

const initialState: centersState = {
	centers: null
};

export const slice = createSlice({
	name: 'centers',
	initialState,
	reducers: {
		setCenters: (state, { payload }: PayloadAction<CenterType[]>) => {
			state.centers = payload;
		},
		clearCenters: (state) => {
			state.centers = null;
		}
	}
});

export const centersName = slice.name;
export const centersReducer = slice.reducer;
export const centersActions = slice.actions;

export const centersSelector = (state: RootState) => state.centers;

export default slice.reducer;
