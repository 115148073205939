// colors.ts

// 색상 정의
export enum COLOR {
	PRIMARY = '#092635',
	PRIMARY_FONT = '#FFFFFF',
	SECONDARY = '#1B4242',
	SECONDARY_FONT = '#FFFFFF',
	ACCENT = '#5C8374',
	ACCENT_FONT = '#333333',
	BACKGROUND = '#9EC8B9',
	BACKGROUND_FONT = '#333333',
	ASIDE = '#1F2937',
	ASIDE_FONT = '#F2F4F6',
	ASIDE_SELECTED = '#374151',
	PAGE_BACKGROUND = '#F2F4F6'
}
