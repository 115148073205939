import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/constants/route.constants';
import { Main } from 'components/common';
import ItemList from 'page/item/itemList/ItemList';
import ItemWriteAndDetailPage from 'page/item/itemWriteAndDetail/itemWriteAndDetail';

const Item: React.FC = () => {
	return (
		<Main>
			<Routes>
				<Route path={ROUTE_PATHS.LIST} element={<ItemList />} />
				<Route path={ROUTE_PATHS.WRITE} element={<ItemWriteAndDetailPage />} />
				<Route path={ROUTE_PATHS.DETAIL} element={<ItemWriteAndDetailPage />} />
			</Routes>
		</Main>
	);
};

export default Item;
