import { useEffect, useRef } from 'react';

interface UseInfiniteScrollProps {
	loading: boolean;
	hasMore: boolean;
	setPage: (callback: (prevPage: number) => number) => void;
}

const useInfiniteScroll = ({ loading, hasMore, setPage }: UseInfiniteScrollProps) => {
	const observer = useRef<IntersectionObserver | null>(null);
	const loader = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (loading || !hasMore) return;
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				setPage((prevPage) => prevPage + 1);
			}
		});

		if (loader.current) observer.current.observe(loader.current);

		// eslint-disable-next-line consistent-return
		return () => {
			if (observer.current) observer.current.disconnect();
		};
	}, [loading, hasMore, setPage]);

	return { loader };
};

export default useInfiniteScroll;
