import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/constants/route.constants';
import { Main } from 'components/common';
import CenterMembershipList from 'page/centerMembership/list/CenterMembershipList';
import CenterMembershipDetail from 'page/centerMembership/detail/CenterMembershipDetail';

const CenterMembership: React.FC = () => {
	return (
		<Main>
			<Routes>
				<Route path={ROUTE_PATHS.LIST} element={<CenterMembershipList />} />
				<Route path={ROUTE_PATHS.DETAIL} element={<CenterMembershipDetail />} />
			</Routes>
		</Main>
	);
};

export default CenterMembership;
