import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/constants/route.constants';
import { Main } from 'components/common';
import GymList from 'page/gym/gymList/GymList';
import GymDetail from 'page/gym/gymDetail/GymDetail';
import GymWrite from 'page/gym/gymWrite/GymWrite';

const Gym: React.FC = () => {
	return (
		<Main>
			<Routes>
				<Route path={ROUTE_PATHS.LIST} element={<GymList />} />
				<Route path={ROUTE_PATHS.WRITE} element={<GymWrite />} />
				<Route path={ROUTE_PATHS.DETAIL} element={<GymDetail />} />
			</Routes>
		</Main>
	);
};

export default Gym;
