import { createSlice } from '@reduxjs/toolkit';
import { UserType } from 'lib/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/';

interface userState {
	user: UserType | null;
}

const initialState: userState = {
	user: null
};

export const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		signIn: (state, { payload }: PayloadAction<UserType>) => {
			state.user = payload;
		},
		signOut: (state) => {
			state.user = null;
		}
	}
});

export const userName = slice.name;
export const userReducer = slice.reducer;
export const userActions = slice.actions;

export const userSelector = (state: RootState) => state.user;

export default slice.reducer;
