import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CenterType, UserType, GymDetailType } from 'lib/types';
import { GymApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import UsersTableWithDelete from 'components/common/user/usersTableWithDelete/UsersTableWithDelete';
import { confirmDelete, preventFormSubmissionOnEnter } from 'utils/function';
import { UserSearchModal } from 'components/common/user/userSearchModal/UserSearchModal';
import { CenterSearchModal } from 'components/common/center/centerSearchModal/CenterSearchModal';
import CentersTableWithDelete from 'components/common/center/centersTableWithDelete/CentersTableWithDelete';
import Loader from 'components/common/loader/Loader';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const GymDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [gym, setGym] = useState<GymDetailType | null>(null);
	const [loading, setLoading] = useState(false);
	const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
	const [isCentersModalOpen, setIsCentersModalOpen] = useState(false);

	const openMembersModal = () => setIsMembersModalOpen(true);
	const closeMembersModal = () => setIsMembersModalOpen(false);

	const openCentersModal = () => setIsCentersModalOpen(true);
	const closeCentersModal = () => setIsCentersModalOpen(false);

	const fetchGym = useCallback(async () => {
		setLoading(true);
		try {
			const response = await GymApi.detail(id);
			setGym(response.gym);
		} catch (error) {
			Toast({ message: 'Error fetching gym details', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchGym();
	}, [fetchGym]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setGym((prevGym) => (prevGym ? { ...prevGym, [name]: value } : null));
	};

	const handleAddManager = (user: UserType) => {
		setGym((prevGym) => {
			if (!prevGym) return null;
			const managers = prevGym.managers || [];
			return { ...prevGym, managers: [...managers, user] };
		});
	};

	const handleRemoveManager = (manager: UserType) => {
		setGym((prevGym) => {
			if (!prevGym) return null;
			const managers = prevGym.managers || [];
			const updatedManagers = managers.filter((existingManager) => existingManager._id !== manager._id);
			return { ...prevGym, managers: updatedManagers };
		});
	};

	const handleAddCenter = (center: CenterType) => {
		setGym((prevGym) => {
			if (!prevGym) return null;
			const centers = prevGym.centers || [];
			return { ...prevGym, centers: [...centers, center] };
		});
	};

	const handleRemoveCenter = (center: CenterType) => {
		setGym((prevGym) => {
			if (!prevGym) return null;
			const centers = prevGym.centers || [];
			const updatedCenters = centers.filter((existingCenter) => existingCenter._id !== center._id);
			return { ...prevGym, centers: updatedCenters };
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!gym) return;
		setLoading(true);
		try {
			delete gym._id;
			delete gym.createdAt;
			delete gym.updatedAt;
			await GymApi.update(id, gym);
			Toast({ message: 'Gym updated successfully', type: ToastType.SUCCESS });
			navigate(-1);
		} catch (error) {
			Toast({ message: 'Error updating gym', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		const result = await confirmDelete('gym');
		if (result.isConfirmed) {
			setLoading(true);
			try {
				await GymApi.delete(id);
				Toast({ message: 'Gym deleted successfully', type: ToastType.SUCCESS });
				navigate(-1);
			} catch (error) {
				Toast({ message: 'Error deleting gym', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		}
	};

	if (loading || !gym) return <Loader />;

	return (
		<Container>
			<h2>Gym Detail</h2>
			<Form onSubmit={handleSubmit} onKeyDown={preventFormSubmissionOnEnter}>
				<Label>Name</Label>
				<Input name="name" value={gym.name || ''} onChange={handleChange} />

				<FlexContainer>
					<Label>Managers</Label>
					<Button type="button" onClick={openMembersModal}>
						Search Users
					</Button>
				</FlexContainer>
				<UsersTableWithDelete users={gym.managers || []} onRemoveUser={handleRemoveManager} />

				<FlexContainer>
					<Label>Centers</Label>
					<Button type="button" onClick={openCentersModal}>
						Search Centers
					</Button>
				</FlexContainer>
				<CentersTableWithDelete centers={gym.centers || []} onRemoveCenter={handleRemoveCenter} />

				<Label>Created At</Label>
				<Input name="createdAt" value={gym.createdAt} readOnly />

				<Label>Updated At</Label>
				<Input name="updatedAt" value={gym.updatedAt} readOnly />

				<ButtonContainer>
					<Button type="submit">Update</Button>
					<Button type="button" onClick={handleDelete}>
						Delete
					</Button>
				</ButtonContainer>
			</Form>
			<UserSearchModal
				isOpen={isMembersModalOpen}
				defaultUsers={gym.managers || []}
				onRequestClose={closeMembersModal}
				onAddUser={handleAddManager}
			/>
			<CenterSearchModal
				isOpen={isCentersModalOpen}
				defaultCenters={gym.centers || []}
				onRequestClose={closeCentersModal}
				onAddCenter={handleAddCenter}
			/>
		</Container>
	);
};

export default GymDetail;
