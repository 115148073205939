import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATHS } from 'utils/constants/route.constants';
import { Main } from 'components/common';
import CenterList from 'page/center/centerList/CenterList';
import CenterDetail from 'page/center/centerDetail/CenterDetail';
import CenterWrite from 'page/center/centerWrite/CenterWrite';

const Center: React.FC = () => {
	return (
		<Main>
			<Routes>
				<Route path={ROUTE_PATHS.LIST} element={<CenterList />} />
				<Route path={ROUTE_PATHS.WRITE} element={<CenterWrite />} />
				<Route path={ROUTE_PATHS.DETAIL} element={<CenterDetail />} />
			</Routes>
		</Main>
	);
};

export default Center;
