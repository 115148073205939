import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ItemType } from 'lib/types';
import { ItemApi } from 'lib';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES, ROUTE_PATHS } from 'utils/constants/route.constants';
import Toast, { ToastType } from 'utils/toast';
import { confirmUpdate, useDebounce } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const AddButton = styled.button`
	height: 40px;
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
`;

const Input = styled.input`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Select = styled.select`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const TableHead = styled.thead`
	background-color: #f8f9fa;
`;

const TableBody = styled.tbody`
	background-color: #fff;
`;

const TableRow = styled.tr`
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	cursor: pointer;
`;

const TableHeader = styled.th`
	padding: 10px;
	text-align: left;
	border-bottom: 1px solid #dee2e6;
`;

const TableCell = styled.td`
	padding: 10px;
	border-bottom: 1px solid #dee2e6;
`;

const ItemList: React.FC = () => {
	const [items, setItems] = useState<ItemType[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [filterKey, setFilterKey] = useState('title');
	const [filters, setFilters] = useState({
		title: ''
	});
	const navigate = useNavigate();

	const handleRowClick = (itemId: string | undefined) => {
		navigate(`${ROUTE_NAMES.ITEM}/${itemId}`); // 디테일 페이지로 이동
	};

	const debouncedFilters = useDebounce(filters, 500);

	const fetchItems = useCallback(async () => {
		setLoading(true);
		try {
			const response = await ItemApi.list({ ...debouncedFilters, page });
			setTotalPages(response.totalPages);
			setItems((prevItems) => {
				if (page === 1) return response.items;
				return [...prevItems, ...response.items];
			});
			setHasMore(page < totalPages);
		} catch (error) {
			setHasMore(false);
			Toast({ message: 'Error fetching items', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [debouncedFilters, page, totalPages]);

	useEffect(() => {
		setPage(1);
	}, []);

	useEffect(() => {
		fetchItems();
	}, [fetchItems]);

	const { loader } = useInfiniteScroll({ loading, hasMore, setPage });

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
	};

	const handleFilterKeyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { value } = e.target;
		setFilters({
			title: ''
		});
		setFilterKey(value);
	};

	const handleActiveClick = async (item: ItemType, event: React.MouseEvent) => {
		event.stopPropagation();
		const result = await confirmUpdate('item active');
		if (result.isConfirmed) {
			const isActive = !item.isActive;
			const { item: updatedItem } = await ItemApi.update(item._id, { isActive });
			const updatedItems = items.map((m) => (m._id === updatedItem._id ? updatedItem : m));
			setItems(updatedItems);
		}
	};

	return (
		<Container>
			<ButtonWrapper>
				<h2>Item List</h2>
				<AddButton onClick={() => navigate(`${ROUTE_NAMES.ITEM}${ROUTE_PATHS.WRITE}`)}>Add Item</AddButton>
			</ButtonWrapper>
			<FilterContainer>
				<Select name="filterKey" onChange={handleFilterKeyChange} value={filterKey}>
					<option value="title">Title</option>
				</Select>
				{filterKey === 'title' && (
					<Input name="title" placeholder="Title" value={filters.title} onChange={handleFilterChange} />
				)}
			</FilterContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableHeader>#</TableHeader>
						<TableHeader>Title</TableHeader>
						<TableHeader>Price</TableHeader>
						<TableHeader>Category</TableHeader>
						<TableHeader>Type</TableHeader>
						<TableHeader>Payment Type</TableHeader>
						<TableHeader>Days</TableHeader>
						<TableHeader>Counts</TableHeader>
						<TableHeader>Active</TableHeader>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item, index) => (
						<TableRow key={item._id} onClick={() => handleRowClick(item._id)}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{item.title}</TableCell>
							<TableCell>{item.price}</TableCell>
							<TableCell>{item.category}</TableCell>
							<TableCell>{item.type}</TableCell>
							<TableCell>{item.paymentType}</TableCell>
							<TableCell>{item.days}</TableCell>
							<TableCell>{item.counts}</TableCell>
							<TableCell onClick={(event) => handleActiveClick(item, event)}>
								{item?.isActive ? 'Active' : 'Inactive'}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div ref={loader} />
			{loading && <Loader />}
		</Container>
	);
};

export default ItemList;
