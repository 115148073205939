export interface CommonMongoType {
	_id?: string;
	createdAt?: string;
	updatedAt?: string;
}

export enum UserRole {
	ADMIN = 'admin',
	GYM_MANAGER = 'gym_manager',
	CENTER_STAFF = 'center_staff',
	CUSTOMER = 'customer',
	GUEST = 'guest'
}

export enum UserGender {
	MALE = 'male',
	FEMALE = 'female'
}

export interface UserType extends CommonMongoType {
	name?: string;
	gender?: UserGender;
	birthDate?: string;
	email?: string;
	phoneNumber?: string;
	password?: string;
	role?: UserRole;
}

export interface PaginatedUsers {
	users: UserType[];
	totalUsers: number;
	totalPages: number;
}

export interface GymType extends CommonMongoType {
	name?: string;
	managers?: UserType[] | string[];
	centers?: CenterType[] | string[];
}

export interface GymDetailType extends Omit<GymType, 'managers' | 'centers'> {
	managers?: UserType[];
	centers?: CenterType[];
}

export interface PaginatedGyms {
	gyms: GymType[] | GymDetailType[];
	totalGyms: number;
	totalPages: number;
}

export enum CenterFacility {
	GYM_CLOTHING = 'gymClothing',
	TOWEL = 'towel',
	LOCKER = 'locker',
	SHOWER = 'shower',
	WIFI = 'wifi',
	PARKING = 'parking',
	BODY_COMPOSITION_ANALYSIS = 'bodyCompositionAnalysis',
	BODY_FAT_TEST = 'bodyFatTest'
}

export interface CenterType extends CommonMongoType {
	name?: string;
	address?: string;
	phoneNumber?: string;
	digitCode?: string;
	facilities?: CenterFacility[];
	location?: {
		latitude?: number;
		longitude?: number;
	};
	operatingTime?: string;
	staffMembers?: UserType[] | string[];
}

export interface CenterDetailType extends Omit<CenterType, 'staffMembers'> {
	staffMembers?: UserType[];
}

export interface PaginatedCenters {
	centers: CenterType[];
	totalCenters: number;
	totalPages: number;
}

export enum SocialLoginTypes {
	GOOGLE = 'google',
	KAKAO = 'kakao',
	APPLE = 'apple'
}

export enum ItemTypes {
	ONCE = 'once', // 일회성
	SUBSCRIPTION = 'subscription', // 정기구독
	ITEM = 'item' // 상품
}

export enum ItemPaymentTypes {
	PERIODIC = 'periodic',
	COUNT_BASED = 'countBased'
}

export enum ItemCategoryTypes {
	PT = 'pt',
	GX = 'gx',
	GOLF = 'golf',
	SWIMMING = 'swimming',
	CROSSFIT = 'crossfit',
	YOGA = 'yoga',
	PILATES = 'pilates',
	ZUMBA = 'zumba'
}

export interface ItemType extends CommonMongoType {
	title?: string;
	price?: number;
	category?: ItemCategoryTypes;
	type: ItemTypes;
	paymentType: ItemPaymentTypes;
	center: CenterType | string;
	days?: number;
	counts?: number;
	isActive?: boolean;
}

export interface ItemDetailType extends Omit<ItemType, 'center'> {
	center: CenterType;
}

export interface PaginatedItems {
	items: ItemType[];
	totalCenters: number;
	totalPages: number;
}

export interface CenterMembershipType extends CommonMongoType {
	center: CenterType;
	user: UserType;
}

export interface PaginatedCenterMemberships {
	centerMemberships: CenterMembershipType[];
	totalCenterMemberships: number;
	totalPages: number;
}

export interface MemoType extends CommonMongoType {
	content?: string;
	user?: UserType;
	author?: UserType;
	centerMembership?: CenterMembershipType;
}

export enum LockerEnumStatus {
	InUse = 'inUse',
	Available = 'available',
	Unavailable = 'unavailable',
	ExpiringSoon = 'expiringSoon',
	Expired = 'expired'
}

export interface LockerType extends CommonMongoType {
	number?: number;
	status?: LockerEnumStatus;
	memo?: string;
	startDate?: string;
	endDate?: string;
}

export interface PaginatedLockers {
	lockers: LockerType[];
	totalLockers: number;
	totalPages: number;
}

export interface LockerGroupType extends CommonMongoType {
	center: CenterType;
	name: string;
	row: number;
	quantity: number;
	lockers?: LockerType[];
}

export interface PaginatedLockerGroups {
	lockerGroups: LockerGroupType[];
	totalLockerGroups: number;
	totalPages: number;
}
