import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MemoType, UserType } from 'lib/types';
import { MemoApi, UserApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { confirmDelete, preventFormSubmissionOnEnter } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import MemosTableWithDelete from 'components/common/memoTableWithDelete/MemoTableWithDelete';
import { UserAddMemoModal } from 'components/common/user/userAddMemoModal/UserAddMemoModal';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/reducers/user';

const UserWrapper = styled.div`
	display: flex;
`;

const LeftContainer = styled.div`
	padding: 20px;
	width: 50%;
	box-sizing: border-box;
`;

const RightContainer = styled.div`
	padding: 20px;
	width: 50%;
	box-sizing: border-box;
`;

const MemoWrapper = styled.div``;

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Select = styled.select`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const UserDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [user, setUser] = useState<UserType | null>(null);
	const [memos, setMemos] = useState<MemoType[]>([]);
	const [loading, setLoading] = useState(false);
	const [isOpenMemoModal, setIsOpenMemoModal] = useState(false);
	const { user: author } = useSelector(userSelector);

	const openMemoModal = () => setIsOpenMemoModal(true);
	const closeMemoModal = () => setIsOpenMemoModal(false);

	const fetchUserAndMemos = useCallback(async () => {
		setLoading(true);
		try {
			const userResponse = await UserApi.detail(id);
			userResponse.user.birthDate = userResponse.user.birthDate
				? new Date(userResponse.user.birthDate).toISOString().split('T')[0]
				: '';
			setUser(userResponse.user);
			if (userResponse.user._id) {
				const memosResponse = await MemoApi.list({
					user: userResponse.user._id,
					populateUser: true,
					populateAuthor: true,
					populateCenterMembership: true
				});
				setMemos(memosResponse.memos);
			}
		} catch (error) {
			Toast({ message: 'Error fetching user details', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchUserAndMemos();
	}, [fetchUserAndMemos]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setUser((prevUser) => (prevUser ? { ...prevUser, [name]: value } : null));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!user) return;
		setLoading(true);
		try {
			delete user._id;
			delete user.createdAt;
			delete user.updatedAt;
			await UserApi.update(id, user);
			Toast({ message: 'User updated successfully', type: ToastType.SUCCESS });
			navigate(-1);
		} catch (error) {
			Toast({ message: 'Error updating user', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		const result = await confirmDelete('user');
		if (result.isConfirmed) {
			setLoading(true);
			try {
				await UserApi.delete(id);
				Toast({ message: 'User deleted successfully', type: ToastType.SUCCESS });
				navigate(-1);
			} catch (error) {
				Toast({ message: 'Error deleting user', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		}
	};

	const handleMemoUpdated = (updatedMemo: MemoType) => {
		setMemos((prevMemos) => prevMemos.map((memo) => (memo._id === updatedMemo._id ? updatedMemo : memo)));
	};

	const handleRemoveMemo = async (memo: MemoType) => {
		const result = await confirmDelete(`memo from ${user?.name}`);
		if (result.isConfirmed) {
			setLoading(true);
			try {
				await MemoApi.delete(memo._id);
				Toast({ message: 'Memo deleted successfully', type: ToastType.SUCCESS });
				setMemos((prevMemos) => prevMemos.filter((m) => m._id !== memo._id));
			} catch (error) {
				Toast({ message: 'Error deleting memo', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		}
	};

	const handleAddMemo = (memo: MemoType) => {
		setMemos((prevMemos) => [...prevMemos, memo]);
	};

	if (loading || !user) return <Loader />;

	return (
		<UserWrapper>
			<LeftContainer>
				<h2>User Detail</h2>
				<Form onSubmit={handleSubmit} onKeyDown={preventFormSubmissionOnEnter}>
					<Label>Name</Label>
					<Input name="name" value={user.name || ''} onChange={handleChange} />

					<Label>Gender</Label>
					<Select name="gender" value={user.gender || 'male'} onChange={handleChange}>
						<option value="male">Male</option>
						<option value="female">Female</option>
					</Select>

					<Label>Birth Date</Label>
					<Input type="date" name="birthDate" value={user.birthDate || ''} onChange={handleChange} />

					<Label>Email</Label>
					<Input type="email" name="email" value={user.email || ''} onChange={handleChange} />

					<Label>Phone Number</Label>
					<Input type="tel" name="phoneNumber" value={user.phoneNumber || ''} onChange={handleChange} />

					<Label>Role</Label>
					<Select name="role" value={user.role || 'Guest'} onChange={handleChange}>
						<option value="admin">Admin</option>
						<option value="gym_manager">Gym Manager</option>
						<option value="center_staff">Center Staff</option>
						<option value="customer">Customer</option>
						<option value="guest">Guest</option>
					</Select>

					<ButtonContainer>
						<Button type="submit">Update</Button>
						<Button type="button" onClick={handleDelete}>
							Delete
						</Button>
					</ButtonContainer>
				</Form>
			</LeftContainer>
			<RightContainer>
				<MemoWrapper>
					<FlexContainer>
						<h2>User Memo</h2>
						<Button type="button" onClick={openMemoModal}>
							Add Memo
						</Button>
					</FlexContainer>
					{isOpenMemoModal && (
						<UserAddMemoModal
							isOpen={isOpenMemoModal}
							user={user}
							author={author as UserType}
							onRequestClose={closeMemoModal}
							onMemoAdded={handleAddMemo}
						/>
					)}
					<MemosTableWithDelete memos={memos} onRemoveMemo={handleRemoveMemo} onUpdateMemos={handleMemoUpdated} />
				</MemoWrapper>
			</RightContainer>
		</UserWrapper>
	);
};

export default UserDetail;
