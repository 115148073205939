import React from 'react';
import styled from 'styled-components';
import { Layout } from 'components/common';

const MainContainer = styled.main`
	width: calc(100% - 200px);
	padding: 20px;
	box-sizing: border-box;
	position: absolute;
	left: 200px;
	background-color: #ecf0f1;
	height: 100vh;
	overflow-y: auto;
`;

const Main: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<Layout>
			<MainContainer>{children}</MainContainer>
		</Layout>
	);
};

export default Main;
