import FormInput from 'components/common/formInput/FormInput';
import CategorySelect from 'components/common/item/categorySelect/CategorySelect';
import PaymentSelect from 'components/common/item/paymentSelect/PaymentSelect';
import TypeSelect from 'components/common/item/typeSelect/TypeSelect';
import Loader from 'components/common/loader/Loader';
import { ItemApi } from 'lib';
import { ItemType, ItemTypes, ItemPaymentTypes } from 'lib/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { confirmDelete, preventFormSubmissionOnEnter } from 'utils/function';
import Toast, { ToastType } from 'utils/toast';

const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const ItemWriteAndDetailPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [item, setItem] = useState<Partial<ItemType>>({});
	const [loading, setLoading] = useState(false);

	const fetchItem = useCallback(async () => {
		setLoading(true);
		try {
			const { item: fetchedItem } = await ItemApi.detail(id);
			setItem(fetchedItem);
		} catch (error) {
			Toast({ message: 'Error fetching gym details', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		id && fetchItem();
	}, [id, fetchItem]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setItem((prevItem) => {
			const updatedItem = {
				...prevItem,
				[name]: value
			};
			if (updatedItem?.paymentType === ItemPaymentTypes.PERIODIC) {
				updatedItem.counts = 0;
			}
			return updatedItem;
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);
		try {
			let preparedItem: Partial<ItemType>;

			if (item.type === ItemTypes.ONCE) {
				preparedItem = {
					...item,
					price: Number(item.price),
					days: Number(item.days),
					counts: item.counts ? Number(item.counts) : undefined
				};
			} else {
				preparedItem = {
					title: item.title,
					price: item.price ? Number(item.price) : undefined,
					center: item.center
				};
			}

			if (id) {
				delete preparedItem._id;
				delete preparedItem.createdAt;
				delete preparedItem.updatedAt;
				await ItemApi.update(id, preparedItem);
				Toast({ message: 'Item updated successfully', type: ToastType.SUCCESS });
			} else {
				await ItemApi.create(preparedItem);
				Toast({ message: 'Item created successfully', type: ToastType.SUCCESS });
			}

			navigate(-1);
		} catch (error) {
			Toast({ message: `Error ${id ? 'updating' : 'creating'} item`, type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		const result = await confirmDelete('item');
		if (result.isConfirmed) {
			setLoading(true);
			try {
				await ItemApi.delete(id);
				Toast({ message: 'Item deleted successfully', type: ToastType.SUCCESS });
				navigate(-1);
			} catch (error) {
				Toast({ message: 'Error deleting item', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		}
	};

	if (loading) return <Loader />;

	return (
		<Container>
			<h2>Write Item</h2>
			<StyledForm onSubmit={handleSubmit} onKeyDown={preventFormSubmissionOnEnter}>
				<FormInput label="상품 정보" name="info" />
				<TypeSelect value={item?.type || ''} onChange={handleChange} />

				{item.type === ItemTypes.ONCE && <CategorySelect value={item?.category || ''} onChange={handleChange} />}
				<FormInput label="Title" name="title" value={item?.title || ''} onChange={handleChange} />
				<FormInput label="Price" name="price" type="number" value={item?.price || 0} onChange={handleChange} />

				{item.type === ItemTypes.ONCE && (
					<>
						<Label htmlFor="sub-info">상품 유형 정보</Label>
						<PaymentSelect value={item?.paymentType || ''} onChange={handleChange} />
						<FormInput label="Days" name="days" type="number" value={item?.days || 0} onChange={handleChange} />
						{ItemPaymentTypes.COUNT_BASED === item?.paymentType && (
							<FormInput label="Counts" name="counts" type="number" value={item?.counts || 0} onChange={handleChange} />
						)}
					</>
				)}
				<FormInput label="Center" name="center" value={item?.center || ''} onChange={handleChange} />
				<ButtonContainer>
					<Button type="submit">{id ? 'Update' : 'Submit'}</Button>
					{id && (
						<Button type="button" onClick={handleDelete}>
							Delete
						</Button>
					)}
				</ButtonContainer>
			</StyledForm>
		</Container>
	);
};

export default ItemWriteAndDetailPage;
