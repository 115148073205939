import { CenterType } from 'lib/types';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import CentersTableWithAdd from 'components/common/center/centersTableWithAdd/CentersTableWithAdd';
import { CenterApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { useDebounce } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

interface CenterSearchModalProps {
	isOpen: boolean;
	defaultCenters: CenterType[] | [];
	onRequestClose: () => void;
	onAddCenter: (center: CenterType) => void;
}

export const CenterSearchModal: React.FC<CenterSearchModalProps> = ({
	isOpen,
	defaultCenters,
	onRequestClose,
	onAddCenter
}) => {
	const [centers, setCenters] = useState<CenterType[]>([]);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [filters, setFilters] = useState({
		name: ''
	});

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
	};

	const debouncedFilters = useDebounce(filters, 500);

	const fetchCenters = useCallback(async () => {
		if (!isOpen) return;
		setLoading(true);
		try {
			const response = await CenterApi.list({ ...debouncedFilters, page });
			setTotalPages(response.totalPages);
			setCenters((prevCenters) => {
				const newCenters = response.centers.filter(
					(newCenter) => !defaultCenters.some((defaultCenter) => defaultCenter._id === newCenter._id)
				);
				return page === 1 ? newCenters : [...prevCenters, ...newCenters];
			});
			setHasMore(page < totalPages);
		} catch (error) {
			setHasMore(false);
			Toast({ message: 'Error fetching centers', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [debouncedFilters, page, totalPages, defaultCenters, setCenters, isOpen]);

	useEffect(() => {
		setPage(1);
	}, [debouncedFilters]);

	useEffect(() => {
		fetchCenters();
	}, [fetchCenters]);

	const { loader } = useInfiniteScroll({ loading, hasMore, setPage });

	const handleAddCenter = (center: CenterType) => onAddCenter(center);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="Center Search Modal"
			ariaHideApp={false} // Suppresses the warning
			shouldCloseOnEsc
		>
			<h2>Search Centers</h2>
			<button type="button" onClick={onRequestClose}>
				Close
			</button>
			<input name="name" placeholder="Name" value={filters.name} onChange={handleFilterChange} />
			<CentersTableWithAdd centers={centers} onAddCenter={handleAddCenter} />
			<div ref={loader} />
			{loading && <Loader />}
		</Modal>
	);
};
