import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserType, UserGender, UserRole } from 'lib/types';
import { UserApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { preventFormSubmissionOnEnter } from 'utils/function';
import Loader from 'components/common/loader/Loader';

const Container = styled.div`
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 350px;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Select = styled.select`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

interface Error {
	data: {
		message: string;
	};
}

const SignUp: React.FC = () => {
	const navigate = useNavigate();
	const [user, setUser] = useState<Partial<UserType>>({
		role: UserRole.GUEST
	});
	const [loading, setLoading] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setUser((prevUser) => ({ ...prevUser, [name]: value }));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);
		try {
			await UserApi.create(user);
			Toast({ message: 'User created successfully', type: ToastType.SUCCESS });
			navigate(-1);
		} catch (error) {
			const err = error as Error;
			Toast({ message: `Error creating user ${err.data.message}`, type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loader />;

	return (
		<Container>
			<Form onSubmit={handleSubmit} onKeyDown={preventFormSubmissionOnEnter}>
				<h2>Sign Up User</h2>
				<Label>Name</Label>
				<Input name="name" value={user.name} onChange={handleChange} />

				<Label>Gender</Label>
				<Select name="gender" value={user.gender} onChange={handleChange}>
					<option value={UserGender.MALE}>Male</option>
					<option value={UserGender.FEMALE}>Female</option>
				</Select>

				<Label>Birth Date</Label>
				<Input type="date" name="birthDate" value={user.birthDate} onChange={handleChange} />

				<Label>Email</Label>
				<Input type="email" name="email" value={user.email} onChange={handleChange} />

				<Label>Phone Number</Label>
				<Input type="tel" name="phoneNumber" value={user.phoneNumber} onChange={handleChange} />

				<Label>Password</Label>
				<Input type="password" name="password" value={user.password} onChange={handleChange} />

				<Label>Role</Label>
				<Select name="role" value={user.role} onChange={handleChange}>
					<option value={UserRole.ADMIN}>Admin</option>
					<option value={UserRole.GYM_MANAGER}>Gym Manager</option>
					<option value={UserRole.CENTER_STAFF}>Center Staff</option>
					<option value={UserRole.CUSTOMER}>Customer</option>
					<option value={UserRole.GUEST}>Guest</option>
				</Select>

				<ButtonContainer>
					<Button type="submit">Submit</Button>
				</ButtonContainer>
			</Form>
		</Container>
	);
};

export default SignUp;
