import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CenterFacility, CenterDetailType, UserType } from 'lib/types';
import { CenterApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { confirmDelete, preventFormSubmissionOnEnter } from 'utils/function';
import { getFacilityIcon } from 'assets/images';
import UsersTableWithDelete from 'components/common/user/usersTableWithDelete/UsersTableWithDelete';
import { UserSearchModal } from 'components/common/user/userSearchModal/UserSearchModal';
import Loader from 'components/common/loader/Loader';
import AddressSearchModal from 'components/common/addressSearchModal/AddressSearchModal';
import KakaoMap from 'components/common/kakaoMap/KakaoMap';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
	z-index: 2000;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
`;

const CheckboxLabel = styled.label`
	margin-right: 20px;
	display: flex;
	align-items: center;
`;

const IconImage = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 5px;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const CenterDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [center, setCenter] = useState<CenterDetailType | null>(null);
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	const openAddressModal = () => setIsAddressModalOpen(true);
	const closeAddressModal = () => setIsAddressModalOpen(false);

	const fetchCenter = useCallback(async () => {
		setLoading(true);
		try {
			const response = await CenterApi.detail(id);
			setCenter(response.center);
		} catch (error) {
			Toast({ message: 'Error fetching center details', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchCenter();
	}, [fetchCenter]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setCenter((prevCenter) => (prevCenter ? { ...prevCenter, [name]: value } : null));
	};

	const handleFacilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.target;
		setCenter((prevCenter) => {
			if (!prevCenter) return null;
			const facilities = prevCenter.facilities || [];
			if (checked) {
				return { ...prevCenter, facilities: [...facilities, value as CenterFacility] };
			}
			return { ...prevCenter, facilities: facilities.filter((facility) => facility !== value) };
		});
	};

	const handleRemoveStaffMembers = (user: UserType) => {
		setCenter((prevCenter) => {
			if (!prevCenter) return null;
			const staffMembers = prevCenter.staffMembers || [];
			const updatedStaffMembers = staffMembers.filter((existingStaffManager) => existingStaffManager._id !== user._id);
			return { ...prevCenter, staffMembers: updatedStaffMembers };
		});
	};

	const handleAddStaffMembers = (user: UserType) => {
		setCenter((prevCenter) => {
			if (!prevCenter) return null;
			const staffMembers = prevCenter.staffMembers || [];
			return { ...prevCenter, staffMembers: [...staffMembers, user] };
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!center) return;
		setLoading(true);
		try {
			delete center._id;
			delete center.createdAt;
			delete center.updatedAt;
			delete center.digitCode;
			await CenterApi.update(id, center);
			Toast({ message: 'Center updated successfully', type: ToastType.SUCCESS });
			navigate(-1);
		} catch (error) {
			Toast({ message: 'Error updating center', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		const result = await confirmDelete('center');
		if (result.isConfirmed) {
			setLoading(true);
			try {
				await CenterApi.delete(id);
				Toast({ message: 'Center deleted successfully', type: ToastType.SUCCESS });
				navigate(-1);
			} catch (error) {
				Toast({ message: 'Error deleting center', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		}
	};

	const handleAddressSelected = (address: string, location: { latitude: number; longitude: number }) => {
		setCenter((prevCenter) => (prevCenter ? { ...prevCenter, address, location } : null));
		closeAddressModal();
	};

	if (loading || !center) return <Loader />;

	return (
		<Container>
			<h2>Center Detail</h2>
			<Form onSubmit={handleSubmit} onKeyDown={preventFormSubmissionOnEnter}>
				<Label>Name</Label>
				<Input name="name" value={center.name || ''} onChange={handleChange} />
				<FlexContainer>
					<Label>Address</Label>
					<Button type="button" onClick={openAddressModal}>
						Search Address
					</Button>
				</FlexContainer>
				<Input name="address" value={center.address || ''} onChange={handleChange} />
				{center.location && <KakaoMap location={center.location} />}
				<Label>Phone Number</Label>
				<Input name="phoneNumber" value={center.phoneNumber || ''} onChange={handleChange} />

				<Label>Digit Code</Label>
				<Input name="digitCode" value={center.digitCode || ''} readOnly />

				<Label>Facilities</Label>
				<CheckboxContainer>
					{Object.values(CenterFacility).map((facility) => (
						<CheckboxLabel key={facility}>
							<input
								type="checkbox"
								value={facility}
								checked={center.facilities?.includes(facility) || false}
								onChange={handleFacilityChange}
							/>
							<IconImage src={getFacilityIcon(facility)} alt={facility} />
							{facility}
						</CheckboxLabel>
					))}
				</CheckboxContainer>

				<Label>Operating Time</Label>
				<Input name="operatingTime" value={center.operatingTime || ''} onChange={handleChange} />

				<FlexContainer>
					<Label>Staff Members</Label>
					<Button type="button" onClick={openModal}>
						Search Users
					</Button>
				</FlexContainer>
				<UsersTableWithDelete users={center.staffMembers || []} onRemoveUser={handleRemoveStaffMembers} />

				<Label>Created At</Label>
				<Input name="createdAt" value={center.createdAt} readOnly />

				<Label>Updated At</Label>
				<Input name="updatedAt" value={center.updatedAt} readOnly />

				<ButtonContainer>
					<Button type="submit">Update</Button>
					<Button type="button" onClick={handleDelete}>
						Delete
					</Button>
				</ButtonContainer>
			</Form>
			<AddressSearchModal
				isOpen={isAddressModalOpen}
				onRequestClose={closeAddressModal}
				onAddressSelected={handleAddressSelected}
			/>
			<UserSearchModal
				isOpen={isModalOpen}
				defaultUsers={center.staffMembers || []}
				onRequestClose={closeModal}
				onAddUser={handleAddStaffMembers}
			/>
		</Container>
	);
};

export default CenterDetail;
