import { toast } from 'react-toastify';

export enum ToastType {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info'
}

interface ToastProps {
	message: string;
	type: ToastType;
}

const Toast = ({ message, type }: ToastProps) => {
	switch (type) {
		case ToastType.SUCCESS:
			return toast.success(message);
		case ToastType.ERROR:
			return toast.error(message);
		case ToastType.WARNING:
			return toast.warning(message);
		case ToastType.INFO:
			return toast.info(message);
		default:
			return null;
	}
};

export default Toast;
