import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'utils/constants/color.constants';
import { NavLink } from 'react-router-dom';
import { ROUTE_NAMES } from 'utils/constants/route.constants';
import { SignOutBtn } from 'components/common';
import { userSelector } from 'store/reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { UserRole } from 'lib/types';
import { gymsSelector } from 'store/reducers/gyms';
import { centersSelector } from 'store/reducers/centers';
import { centerActions, centerSelector } from 'store/reducers/center';
import { gymActions, gymSelector } from 'store/reducers/gym';

const AsideNavContainer = styled.aside`
	width: 200px;
	height: 100vh;
	background-color: ${COLOR.ASIDE};
	color: ${COLOR.ASIDE_FONT};
	padding: 20px;
	box-sizing: border-box;
`;

const NavUpper = styled.div`
	display: flex;
	height: 90%;
	flex-direction: column;
`;

const NavLower = styled.div``;

const NavItem = styled(NavLink)`
	color: white;
	text-decoration: none;
	margin-bottom: 20px;
	padding: 10px;
	&:hover {
		background-color: ${COLOR.ASIDE_SELECTED};
		padding: 10px;
		border-radius: 5px;
	}
`;

const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid white;
	border-radius: 5px;
	padding: 10px;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Select = styled.select`
	margin-bottom: 10px;
`;

const AsideNav: React.FC = () => {
	const dispatch = useDispatch();
	const { user } = useSelector(userSelector);
	const { gyms } = useSelector(gymsSelector);
	const { gym } = useSelector(gymSelector);
	const { centers } = useSelector(centersSelector);
	const { center } = useSelector(centerSelector);

	const isAdminOrGymManager = user?.role === UserRole.ADMIN || user?.role === UserRole.GYM_MANAGER;
	const isGymManagerOrCenterStaff = user?.role === UserRole.GYM_MANAGER || user?.role === UserRole.CENTER_STAFF;
	const isAdmin = user?.role === UserRole.ADMIN;
	const isGymManager = user?.role === UserRole.GYM_MANAGER;

	const handleGymChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedGymId = e.target.value;
		const selectedGym = gyms?.find((gymData) => gymData._id === selectedGymId);
		if (selectedGym) dispatch(gymActions.setGym(selectedGym));
	};

	const handleCenterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedCenterId = e.target.value;
		const selectedCenter = centers?.find((centerData) => centerData._id === selectedCenterId);
		if (selectedCenter) dispatch(centerActions.setCenter(selectedCenter));
	};

	return (
		<AsideNavContainer>
			<NavUpper>
				<NavItem to={ROUTE_NAMES.HOME}>Home</NavItem>
				{isGymManagerOrCenterStaff && (
					<SelectContainer>
						{isAdminOrGymManager && (
							<>
								<Label>Gym Select</Label>
								<Select onChange={handleGymChange} value={gym?._id || ''}>
									<option value="">--Select a Gym--</option>
									{gyms?.map((gymData) => (
										<option key={gymData._id} value={gymData._id}>
											{gymData.name}
										</option>
									))}
								</Select>
							</>
						)}
						{isGymManagerOrCenterStaff && (
							<>
								<Label>Center Select</Label>
								<Select onChange={handleCenterChange} value={center?._id || ''}>
									<option value="">--Select a Center--</option>
									{centers?.map((centerData) => (
										<option key={centerData._id} value={centerData._id}>
											{centerData.name}
										</option>
									))}
								</Select>
							</>
						)}
					</SelectContainer>
				)}
				{isAdmin && <NavItem to={ROUTE_NAMES.GYM}>Gym</NavItem>}
				{isGymManager && gym && <NavItem to={`${ROUTE_NAMES.GYM}/${gym._id}`}>Gym</NavItem>}
				{isAdmin && <NavItem to={ROUTE_NAMES.CENTER}>Center</NavItem>}
				{isGymManagerOrCenterStaff && center && <NavItem to={`${ROUTE_NAMES.CENTER}/${center._id}`}>Center</NavItem>}
				<NavItem to={ROUTE_NAMES.CENTER_MEMBERSHIP}>Center User</NavItem>
				<NavItem to={ROUTE_NAMES.LOCKER}>Locker</NavItem>
				<NavItem to={ROUTE_NAMES.LESSON}>Lesson</NavItem>
				{isAdmin && <NavItem to={ROUTE_NAMES.ITEM}>Item</NavItem>}
				{isAdmin && <NavItem to={ROUTE_NAMES.USER}>User</NavItem>}
			</NavUpper>
			<NavLower>
				<NavItem to={`${ROUTE_NAMES.USER}/${user?._id}`}>My Page</NavItem>
				<SignOutBtn />
			</NavLower>
		</AsideNavContainer>
	);
};

export default AsideNav;
