import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CenterMembershipType, MemoType, UserType } from 'lib/types';
import { CenterMembershipApi, MemoApi } from 'lib';
import Toast, { ToastType } from 'utils/toast';
import { confirmDelete, preventFormSubmissionOnEnter } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import { UserAddMemoModal } from 'components/common/user/userAddMemoModal/UserAddMemoModal';
import MemosTableWithDelete from 'components/common/memoTableWithDelete/MemoTableWithDelete';
import { userSelector } from 'store/reducers/user';
import { useSelector } from 'react-redux';

// 스타일 정의
const Container = styled.div`
	display: flex;
`;

const LeftContainer = styled.div`
	padding: 20px;
	width: 50%;
	box-sizing: border-box;
`;

const RightContainer = styled.div`
	padding: 20px;
	width: 50%;
	box-sizing: border-box;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

const Button = styled.button`
	padding: 10px;
	margin-right: 10px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const MemoWrapper = styled.div``;

const CenterMembershipDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [centerMembership, setCenterMembership] = useState<CenterMembershipType | null>(null);
	const [user, setUser] = useState<UserType | null>(null);
	const [memos, setMemos] = useState<MemoType[]>([]);
	const [loading, setLoading] = useState(false);
	const [isOpenMemoModal, setIsOpenMemoModal] = useState(false);
	const { user: author } = useSelector(userSelector);

	const openMemoModal = () => setIsOpenMemoModal(true);
	const closeMemoModal = () => setIsOpenMemoModal(false);

	const fetchCenterMembership = useCallback(async () => {
		setLoading(true);
		try {
			const response = await CenterMembershipApi.detail(id);
			setUser(response.centerMembership.user);
			setCenterMembership(response.centerMembership);
			if (response.centerMembership.user._id) {
				const memosResponse = await MemoApi.list({
					centerMembership: response.centerMembership._id,
					user: response.centerMembership.user._id,
					populateUser: true,
					populateAuthor: true,
					populateCenterMembership: true
				});
				setMemos(memosResponse.memos);
			}
		} catch (error) {
			Toast({ message: 'Error fetching centerMembership details', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchCenterMembership();
	}, [fetchCenterMembership]);

	const handleDelete = async () => {
		const result = await confirmDelete('Center Membership');
		if (result.isConfirmed) {
			setLoading(true);
			try {
				await CenterMembershipApi.delete(id);
				Toast({ message: 'CenterMembership deleted successfully', type: ToastType.SUCCESS });
				navigate(-1);
			} catch (error) {
				Toast({ message: 'Error deleting centerMembership', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		}
	};

	const handleMemoUpdated = (updatedMemo: MemoType) => {
		setMemos((prevMemos) => prevMemos.map((memo) => (memo._id === updatedMemo._id ? updatedMemo : memo)));
	};

	const handleRemoveMemo = async (memo: MemoType) => {
		const result = await confirmDelete(`memo from ${centerMembership?.user?.name}`);
		if (result.isConfirmed) {
			setLoading(true);
			try {
				await MemoApi.delete(memo._id);
				Toast({ message: 'Memo deleted successfully', type: ToastType.SUCCESS });
				setMemos((prevMemos) => prevMemos.filter((m) => m._id !== memo._id));
			} catch (error) {
				Toast({ message: 'Error deleting memo', type: ToastType.ERROR });
			} finally {
				setLoading(false);
			}
		}
	};

	const handleAddMemo = (memo: MemoType) => {
		setMemos((prevMemos) => [...prevMemos, memo]);
	};

	if (loading || !centerMembership) return <Loader />;

	return (
		<Container>
			<LeftContainer>
				<h2>CenterMembership Detail</h2>
				<Form onKeyDown={preventFormSubmissionOnEnter}>
					<Label>Name</Label>
					<Input name="name" value={centerMembership.user.name || ''} readOnly />

					<Label>Gender</Label>
					<Input name="gender" value={centerMembership.user.gender || ''} readOnly />

					<Label>Birth Date</Label>
					<Input
						name="birthDate"
						type="date"
						value={
							(centerMembership.user.birthDate &&
								new Date(centerMembership.user.birthDate).toISOString().split('T')[0]) ||
							''
						}
						readOnly
					/>

					<Label>Email</Label>
					<Input type="email" name="email" value={centerMembership.user.email || ''} readOnly />

					<Label>Phone Number</Label>
					<Input type="tel" name="phoneNumber" value={centerMembership.user.phoneNumber || ''} readOnly />

					<Label>Created At</Label>
					<Input name="createdAt" value={centerMembership.createdAt} readOnly />

					<Label>Updated At</Label>
					<Input name="updatedAt" value={centerMembership.updatedAt} readOnly />
					<ButtonContainer>
						<Button type="button" onClick={handleDelete}>
							Delete
						</Button>
					</ButtonContainer>
				</Form>
			</LeftContainer>
			<RightContainer>
				<MemoWrapper>
					<FlexContainer>
						<h2>User Memo</h2>
						<Button type="button" onClick={openMemoModal}>
							Add Memo
						</Button>
					</FlexContainer>
					{isOpenMemoModal && (
						<UserAddMemoModal
							isOpen={isOpenMemoModal}
							user={user as UserType}
							author={author as UserType}
							centerMembership={centerMembership}
							onRequestClose={closeMemoModal}
							onMemoAdded={handleAddMemo}
						/>
					)}
					<MemosTableWithDelete memos={memos} onRemoveMemo={handleRemoveMemo} onUpdateMemos={handleMemoUpdated} />
				</MemoWrapper>
			</RightContainer>
		</Container>
	);
};

export default CenterMembershipDetail;
