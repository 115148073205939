import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { CenterType, UserRole } from 'lib/types';
import { CenterApi } from 'lib';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NAMES, ROUTE_PATHS } from 'utils/constants/route.constants';
import Toast, { ToastType } from 'utils/toast';
import { getFacilityIcon } from 'assets/images';
import { useDebounce } from 'utils/function';
import Loader from 'components/common/loader/Loader';
import { centersSelector } from 'store/reducers/centers';
import { userSelector } from 'store/reducers/user';
import { useSelector } from 'react-redux';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

// 스타일 정의
const Container = styled.div`
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;

const AddButton = styled.button`
	height: 40px;
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const FilterContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
`;

const Input = styled.input`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Select = styled.select`
	margin-right: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	flex: 1;
	min-width: 150px;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const TableHead = styled.thead`
	background-color: #f8f9fa;
`;

const TableBody = styled.tbody`
	background-color: #fff;
`;

const TableRow = styled.tr`
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	cursor: pointer;
`;

const TableHeader = styled.th`
	padding: 10px;
	text-align: left;
	border-bottom: 1px solid #dee2e6;
`;

const TableCell = styled.td`
	padding: 10px;
	border-bottom: 1px solid #dee2e6;
`;

const IconWrapper = styled.span`
	display: flex;
	align-items: center;
`;

const IconImage = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 5px;
`;

const CenterList: React.FC = () => {
	const [centers, setCenters] = useState<CenterType[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [filterKey, setFilterKey] = useState('name');
	const [hasMore, setHasMore] = useState(true);
	const [filters, setFilters] = useState({
		name: '',
		address: '',
		phoneNumber: ''
	});
	const navigate = useNavigate();
	const { user } = useSelector(userSelector);
	const { centers: userCenters } = useSelector(centersSelector);

	const debouncedFilters = useDebounce(filters, 500);

	const fetchCenters = useCallback(async () => {
		setLoading(true);
		try {
			if (user?.role !== UserRole.ADMIN && userCenters) {
				setCenters(userCenters);
				setHasMore(false);
			} else if (user?.role === UserRole.ADMIN) {
				const response = await CenterApi.list({ ...debouncedFilters, page });
				setTotalPages(response.totalPages);
				setCenters((prevCenters) => {
					if (page === 1) return response.centers;
					return [...prevCenters, ...response.centers];
				});
				setHasMore(page < totalPages);
			}
		} catch (error) {
			setHasMore(false);
			Toast({ message: 'Error fetching centers', type: ToastType.ERROR });
		} finally {
			setLoading(false);
		}
	}, [debouncedFilters, page, totalPages, user, userCenters]);

	useEffect(() => {
		setPage(1);
	}, []);

	useEffect(() => {
		fetchCenters();
	}, [fetchCenters]);

	const { loader } = useInfiniteScroll({ loading, hasMore, setPage });

	const handleRowClick = (centerId: string | undefined) => {
		navigate(`${ROUTE_NAMES.CENTER}/${centerId}`); // 디테일 페이지로 이동
	};

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[name]: value
		}));
	};

	const handleFilterKeyChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { value } = e.target;
		setFilters({
			name: '',
			address: '',
			phoneNumber: ''
		});
		setFilterKey(value);
	};

	return (
		<Container>
			<ButtonWrapper>
				<h2>Center List</h2>
				{user?.role === UserRole.ADMIN && (
					<AddButton onClick={() => navigate(`${ROUTE_NAMES.CENTER}${ROUTE_PATHS.WRITE}`)}>Add Center</AddButton>
				)}
			</ButtonWrapper>
			<FilterContainer>
				<Select name="filterKey" onChange={handleFilterKeyChange} value={filterKey}>
					<option value="name">Name</option>
					<option value="address">Address</option>
					<option value="phoneNumber">Phone Number</option>
				</Select>
				{filterKey === 'name' && (
					<Input name="name" placeholder="Name" value={filters.name} onChange={handleFilterChange} />
				)}
				{filterKey === 'address' && (
					<Input name="address" placeholder="Address" value={filters.address} onChange={handleFilterChange} />
				)}
				{filterKey === 'phoneNumber' && (
					<Input
						name="phoneNumber"
						placeholder="Phone Number"
						value={filters.phoneNumber}
						onChange={handleFilterChange}
					/>
				)}
			</FilterContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableHeader>#</TableHeader>
						<TableHeader>Name</TableHeader>
						<TableHeader>Address</TableHeader>
						<TableHeader>Phone Number</TableHeader>
						<TableHeader>Digit Code</TableHeader>
						<TableHeader>Facilities</TableHeader>
						<TableHeader>Operating Time</TableHeader>
						<TableHeader>Staff Members</TableHeader>
					</TableRow>
				</TableHead>
				<TableBody>
					{centers.map((center, index) => (
						<TableRow key={center._id} onClick={() => handleRowClick(center._id)}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{center.name}</TableCell>
							<TableCell>{center.address}</TableCell>
							<TableCell>{center.phoneNumber}</TableCell>
							<TableCell>{center.digitCode}</TableCell>
							<TableCell>
								{center.facilities?.map((facility) => (
									<IconWrapper key={facility}>
										<IconImage src={getFacilityIcon(facility)} alt={facility} />
										{facility}
									</IconWrapper>
								))}
							</TableCell>
							<TableCell>{center.operatingTime}</TableCell>
							<TableCell>{center.staffMembers?.length}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div ref={loader} />
			{loading && <Loader />}
		</Container>
	);
};

export default CenterList;
