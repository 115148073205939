import React from 'react';
import styled from 'styled-components';
import { AsideNav } from 'components/common';

const LayoutContainer = styled.div`
	display: flex;
`;

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<LayoutContainer>
			<AsideNav />
			{children}
		</LayoutContainer>
	);
};

export default Layout;
