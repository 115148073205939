import React, { useEffect } from 'react';
import { Route, Routes, useLocation, BrowserRouter, useNavigate } from 'react-router-dom';
import { ROUTE_NAMES, ROUTE_PATHS } from 'utils/constants/route.constants';
import Cookies from 'js-cookie';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { userActions, userSelector } from 'store/reducers/user';
import { CenterApi, GymApi, UserApi } from 'lib';
import { store } from 'store';
import { Center, CenterMembership, Gym, Home, Lesson, Locker, Item, NotFoundPage, SignIn, SignUp, User } from 'page';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { gymsActions, gymsSelector } from 'store/reducers/gyms';
import { centersActions, centersSelector } from 'store/reducers/centers';
import { CenterType, UserRole } from 'lib/types';
import { centerActions } from 'store/reducers/center';
import { gymActions } from 'store/reducers/gym';

const CustomToastContainer = styled(ToastContainer)`
	.Toastify__toast {
	}
`;

const AppRoutes: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector(userSelector);
	const { gyms } = useSelector(gymsSelector);
	const { centers } = useSelector(centersSelector);
	const { pathname } = useLocation();

	useEffect(() => {
		const token = Cookies.get('token');
		const publicRoutes = [ROUTE_NAMES.POLICY, ROUTE_NAMES.SIGN_IN, ROUTE_NAMES.SIGN_UP];

		if (!token) {
			// 토큰이 없으면 유저와 토큰 초기화
			dispatch(userActions.signOut()); // 유저 상태 초기화
			Cookies.remove('token'); // 토큰 삭제
			if (!publicRoutes.includes(pathname as ROUTE_NAMES)) {
				navigate(ROUTE_NAMES.SIGN_IN, { state: { from: pathname } });
			}
			return;
		}

		const getGyms = async () => {
			try {
				const res = await GymApi.me();
				dispatch(gymsActions.setGyms(res.gyms));
				dispatch(gymActions.setGym(res.gyms[0]));
				const centersData = res.gyms
					.flatMap((gym) => gym.centers)
					.filter((center): center is CenterType => center !== undefined) as CenterType[];
				if (centersData.length > 0) {
					dispatch(centersActions.setCenters(centersData));
					dispatch(centerActions.setCenter(centersData[0]));
				}
			} catch (error) {
				dispatch(gymsActions.clearGyms());
				dispatch(centersActions.clearCenters());
			}
		};
		if (user?.role === UserRole.GYM_MANAGER && !gyms) getGyms();

		const getCenters = async () => {
			try {
				const res = await CenterApi.me();
				dispatch(centersActions.setCenters(res.centers));
				dispatch(centerActions.setCenter(res.centers[0]));
			} catch (error) {
				dispatch(centersActions.clearCenters());
				dispatch(centerActions.clearCenter());
			}
		};

		if (user?.role === UserRole.CENTER_STAFF && !centers) getCenters();

		if (user) return;

		const getUser = async () => {
			try {
				const res = await UserApi.me();
				if (res.user) {
					dispatch(userActions.signIn(res.user));
				} else if (!publicRoutes.includes(pathname as ROUTE_NAMES)) {
					navigate(ROUTE_NAMES.SIGN_IN, { state: { from: pathname } });
				}
			} catch (error) {
				if (!publicRoutes.includes(pathname as ROUTE_NAMES)) {
					navigate(ROUTE_NAMES.SIGN_IN, { state: { from: pathname } });
				}
			}
		};

		getUser();
	}, [dispatch, navigate, pathname, user, gyms, centers]);

	return (
		<Routes>
			<Route path={ROUTE_NAMES.HOME} element={<Home />} />
			<Route path={ROUTE_NAMES.SIGN_IN} element={<SignIn />} />
			<Route path={ROUTE_NAMES.SIGN_UP} element={<SignUp />} />
			<Route path={`${ROUTE_NAMES.GYM}${ROUTE_PATHS.ALL}`} element={<Gym />} />
			<Route path={`${ROUTE_NAMES.CENTER}${ROUTE_PATHS.ALL}`} element={<Center />} />
			<Route path={`${ROUTE_NAMES.USER}${ROUTE_PATHS.ALL}`} element={<User />} />
			<Route path={`${ROUTE_NAMES.ITEM}${ROUTE_PATHS.ALL}`} element={<Item />} />
			<Route path={`${ROUTE_NAMES.CENTER_MEMBERSHIP}${ROUTE_PATHS.ALL}`} element={<CenterMembership />} />
			<Route path={`${ROUTE_NAMES.LOCKER}${ROUTE_PATHS.ALL}`} element={<Locker />} />
			<Route path={`${ROUTE_NAMES.LESSON}${ROUTE_PATHS.ALL}`} element={<Lesson />} />
			<Route path={ROUTE_PATHS.ALL} element={<NotFoundPage />} />
		</Routes>
	);
};

const App: React.FC = () => {
	return (
		<>
			<CustomToastContainer position="top-right" autoClose={1000} closeOnClick closeButton={false} hideProgressBar />
			<Provider store={store}>
				<BrowserRouter>
					<AppRoutes />
				</BrowserRouter>
			</Provider>
		</>
	);
};

export default App;
