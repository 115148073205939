import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
	margin-bottom: 10px;
	font-weight: bold;
`;

const Input = styled.input`
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
`;

interface FormInputProps {
	label?: string;
	name?: string;
	type?: string;
	value?: any;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({ label, name, type = 'text', value, onChange }) => (
	<>
		{label && <Label htmlFor={name}>{label}</Label>}
		{onChange && <Input name={name} type={type} value={value} onChange={onChange} />}
	</>
);

export default FormInput;
