import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CurrentTimeIndicator from '../lessonTime/LessonTimeIndicator';

interface LessonTableProps {
	instructors: string[];
	lessons: { [key: string]: { [key: number]: string } };
}

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1px solid black;
	padding: 8px;
	text-align: center;
`;

const Td = styled.td`
	border: 1px solid black;
	padding: 8px;
	text-align: center;
	&:hover {
		background-color: #f3aea0;
	}
`;

const TimeCell = styled(Td)`
	font-weight: bold;
`;

const HighlightRow = styled.tr`
	position: relative;
`;

const LessonTable: React.FC<LessonTableProps> = ({ instructors, lessons }) => {
	const [currentHour, setCurrentHour] = useState(new Date().getHours());
	const [currentMinutes, setCurrentMinutes] = useState(new Date().getMinutes());

	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date();
			setCurrentHour(now.getHours());
			setCurrentMinutes(now.getMinutes());
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	const hours = Array.from({ length: 24 }, (_, i) => i);

	return (
		<Table>
			<thead>
				<tr>
					<Th>Time</Th>
					{instructors.map((instructor) => (
						<Th key={instructor}>{instructor}</Th>
					))}
				</tr>
			</thead>
			<tbody>
				{hours.map((hour) => (
					<HighlightRow key={hour}>
						<TimeCell>{`${hour}:00`}</TimeCell>
						{instructors.map((instructor) => {
							const lesson = lessons[instructor]?.[hour];
							return (
								<Td
									key={instructor}
									onClick={lesson ? () => alert(`Go to lesson: ${lesson}`) : undefined}
									style={{ cursor: lesson ? 'pointer' : 'default' }}
								>
									{lesson}
								</Td>
							);
						})}
						{/* 현재 시간에 해당하는 행에 빨간 줄 표시 */}
						{currentHour === hour && <CurrentTimeIndicator top={(currentMinutes / 60) * 100} />}
					</HighlightRow>
				))}
			</tbody>
		</Table>
	);
};

export default LessonTable;
