import React, { useState } from 'react';
import styled from 'styled-components';
import { MemoType } from 'lib/types';
import { UserUpdateMemoModal } from 'components/common/user/userUpdateMemoModal/UserUpdateMemoModal';

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
`;

const Td = styled.td`
	border: 1px solid #ddd;
	padding: 8px;
`;

const ContentTd = styled(Td)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: red;
`;

const MemosTableWithDelete: React.FC<{
	memos: MemoType[];
	onRemoveMemo: (memo: MemoType) => void;
	onUpdateMemos: (memo: MemoType) => void;
}> = ({ memos, onRemoveMemo, onUpdateMemos }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedMemo, setSelectedMemo] = useState<MemoType | null>(null);

	const handleRemoveMemo = (memo: MemoType) => onRemoveMemo(memo);

	const handleMemoClick = (memo: MemoType) => {
		setSelectedMemo(memo);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setSelectedMemo(null);
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						<Th>Content</Th>
						<Th>Author</Th>
						<Th>Center</Th>
						<Th>Delete</Th>
					</tr>
				</thead>
				<tbody>
					{memos.map((memo) => (
						<tr key={memo._id}>
							<ContentTd onClick={() => handleMemoClick(memo)}>{memo.content}</ContentTd>
							<Td>{memo.author?.name}</Td>
							<Td>{memo.centerMembership?.center?.name}</Td>
							<Td>
								<Button onClick={() => handleRemoveMemo(memo)}>x</Button>
							</Td>
						</tr>
					))}
				</tbody>
			</Table>
			{selectedMemo && (
				<UserUpdateMemoModal
					isOpen={isModalOpen}
					memo={selectedMemo}
					onRequestClose={handleCloseModal}
					onMemoUpdated={onUpdateMemos}
				/>
			)}
		</>
	);
};

export default MemosTableWithDelete;
